<script>
export let label = ""
export let value = undefined
export let options = []

function isObject(val) {
  return val && typeof val === "object" && val.constructor === Object
}

function processOptions() {
  if (options.length === 0) return []
  return options.map((x) =>
    isObject(x) && "label" in x && "value" in x ? x : { label: x, value: x }
  )
}

$: _options = processOptions(options)

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0
</script>

<div class="w-full mb-4">
  {#if label}
    <p class="mb-2" class:text-red-500="{hasErrors}">{label}</p>
  {/if}
  {#each _options as option}
    <label class="cursor-pointer" class:text-red-500="{hasErrors}">
      <input type="radio" bind:group="{value}" value="{option.value}" />
      {option.label}
    </label>
  {/each}
  {#if hasErrors}
    <div class="mt-2 text-red-500">{errors[0]}</div>
  {/if}
</div>

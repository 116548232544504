<script>
import { referral } from "@local/store/referral"
import Default from "./default.svelte"
import NyCardCampaign from "./nyCardCampaign.svelte"
import PaCardCampaign from "./paCardCampaign.svelte"
import TilrayCampaign from "./tilrayCampaign.svelte"
import PapTracker from "./papTracker.svelte"
import JaneTracker from "./janeTracker.svelte"

export let subdomain

let tracker = Default

function updateTracker(subdomain, referral) {
  if (subdomain === "newyork" && referral && referral.code === "nyCard") {
    tracker = NyCardCampaign
    return
  }
  if (subdomain === "pennsylvania" && referral && referral.code === "paCard") {
    tracker = PaCardCampaign
    return
  }
  if (subdomain === "tilray") {
    tracker = TilrayCampaign
    return
  }
  if (subdomain === "leafly") {
    tracker = PapTracker
    return
  }
  if (subdomain === "illinois" && referral && referral.code === "home") {
    tracker = JaneTracker
    return
  }
  tracker = Default
}

$: updateTracker(subdomain, $referral)
</script>

<svelte:component this="{tracker}" />

import { writable } from "svelte/store"

export const workflowKey = writable(null)
export const workflowLang = writable("en")

export function setWorkflowKey(value) {
  workflowKey.set(value)
}

export async function setWorkflowLang(value) {
  if(value) {
    workflowLang.set(value)
  }
}

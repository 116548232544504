<script>
import { onMount, tick } from "svelte"
import queryString from "query-string"
import { navigate } from "svelte-routing"

import { anonymousApi as API, handleErrors } from "@local/utils/api"
import {
  partnerId as _partnerId,
  loginDisabled,
  externalLogin,
  externalLoginInfo,
  externalLoginCTAUrl,
  externalLoginCTA,
} from "@local/store/theme"
import { auth } from "@local/store/auth"
import { isLoggedIn, isEmailConfirmed } from "@local/store/auth"

import Link from "@local/components/Link.svelte"
import TwoLayout from "@shared/components/TwoLayout.svelte"
import TextInput from "@shared/components/TextInput.svelte"
import Heading from "@local/components/Heading.svelte"
import SubHeading from "@local/components/SubHeading.svelte"
import Submit from "@local/components/Submit.svelte"
import ButtonLink from "@local/components/ButtonLink.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  emailText,
  passwordText,
  signInText,
  welcomeBackText,
  loginOrPasswordIncorrectText,
  forgotPasswordText,
  importantMessageText,
} from "@shared/utils/translations"

export let location = {}

$: subdomain = window.location.hostname.split(".")[0]

let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next

$: partnerId = $_partnerId
let form = {
  email: "",
  password: "",
}
let errors = {}

async function submit() {
  try {
    const partnerPath = `partners/${partnerId}/`
    const tokens = await API.post(`${partnerPath}tokens`, {
      json: form,
      searchParams: { next },
    }).json()
    const { partnerOrigin } = auth(tokens)
    if (partnerOrigin !== subdomain) {
      window.location = `SVELTE_APP_PROTOCOL://${partnerOrigin}.SVELTE_APP_DOMAIN/login${location.search}`
      return
    }
    await tick()
    navigate(next || "/", { replace: true })
  } catch (err) {
    console.log({ err })
    let customErrMsgs = {
      email: {
        shouldBeValid: loginOrPasswordIncorrectText[$workflowLang],
      },
    }
    errors = await handleErrors({ err, customErrMsgs })
  }
}

onMount(async () => {
  if ($loginDisabled || ($isLoggedIn && $isEmailConfirmed)) {
    navigate(next || "/", { replace: true })
  }
})
</script>

<TwoLayout>
  {#if $externalLogin}
    <Heading
      content="{importantMessageText[$workflowLang]}"
      className="mb-12"
    />
    <SubHeading
      content="{$externalLoginInfo}"
      className="text-align-center font-weight-normal font-size-lg mb-12"
    />
    <ButtonLink
      url="{$externalLoginCTAUrl}"
      label="{$externalLoginCTA}"
      color="primary"
    />
  {:else}
    <form on:submit|preventDefault autocomplete="off" on:submit="{submit}">
      <Heading content="{welcomeBackText[$workflowLang]}" className="mb-12" />
      {#if errors.email}
        <div class="mb-6 text-center text-red-500">{errors.email[0]}</div>
      {/if}
      <TextInput
        name="email"
        label="{emailText[$workflowLang]}*"
        bind:value="{form.email}"
        class="mb-6"
      />
      <TextInput
        name="password"
        label="{passwordText[[$workflowLang]]}*"
        bind:value="{form.password}"
        type="password"
        class="mb-12"
      />
      <Submit
        label="{signInText[$workflowLang]}"
        color="primary"
        className="mb-6"
      />
      <div class="text-center">
        <Link href="/forgot-password{location.search}" class="font-size-normal">
          {forgotPasswordText[$workflowLang]}
        </Link>
      </div>
    </form>
  {/if}
</TwoLayout>

<style>
.hero {
  @apply relative flex w-full h-140;
  @apply text-white bg-cover bg-left;
  background-image: var(--landing-hero-path);
}

@screen md {
  .hero {
    @apply h-224;
  }
}

.background-text {
  background-color: var(--landing-hero-layer-bg-color);
}

.icon {
  @apply text-5xl mb-4;
}

.max-w-screen-2xl {
  max-width: 1580px;
}

.center-image {
  @apply w-full h-full;
  @apply bg-cover bg-left;
  background-image: var(--landing-medical-center-path);
}

.answer-visible {
  @apply block;
}

.question-primary {
  color: var(--primary);
}

.arrow {
  @apply inline-block ml-auto;
  @apply p-1 border-t-2 border-r-2 border-black;
  transform: rotate(135deg);
}

.arrow-opened {
  transform: rotate(-45deg);
}

.bar {
  background-color: var(--bar-color);
}
</style>

<script>
import { onMount } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"

import FullLayout from "@shared/components/FullLayout.svelte"
import Button from "@local/components/Button.svelte"
import PageTemplate from "@local/components/PageTemplate.svelte"
import { partner as _partner } from "@local/store/theme"
import { referral as _referral } from "@local/store/referral"
import { setReferralUtms } from "@local/store/referralUtms"
import { isLoggedIn } from "@local/store/auth"
import {
  logoAlt,
  infoSectionFontColor,
  linkSectionFontColor,
  externalLogin,
  registrationDisabled,
} from "@local/store/theme"
import { setWorkflowLang, workflowLang } from "@shared/store/workflowKey"
import {
  learnMoreText,
  generalQuestionsText,
  usefulLinks,
  contactUs,
  privacyPolicyText,
  allRightsReservedText,
} from "@shared/utils/translations"
import { setPartner } from "@local/store/theme"

$: partnerLandingPage = $_partner.origin.landingPage
$: referralPage = $_referral ? $_referral.props : ""
$: referralLandingPage =
  referralPage && referralPage.landingPage ? referralPage.landingPage : ""
$: faqItems = referralLandingPage.faqItems
  ? referralLandingPage.faqItems
  : partnerLandingPage.faqItems

$: setPartner($_partner, $_referral)

let queryParams = queryString.parse(location.search)
let lang = queryParams?.lang
let privacyPolicyUrl = ""
let learnMoreUrl = ""
let queryGetStarted
let page

if ($_partner.name === "HelloMD") {
  learnMoreUrl = "https://hellomd.com"
  privacyPolicyUrl = "https://hellomd.com/privacy-policy"
} else {
  learnMoreUrl = "https://hellomd.ca"
  privacyPolicyUrl = "https://hellomd.ca/privacy-policy"
}

if (lang) {
  setWorkflowLang(lang)
}

let openedQuestion = null

function scrollFromHero() {
  document
    .getElementById("icons-section")
    .scrollIntoView({ behavior: "smooth" })
}

function openQuestion(i) {
  if (openedQuestion === i) {
    openedQuestion = null
    return
  }

  openedQuestion = i
}

onMount(() => {
  const { utm_source: referralUtmSource, utm_campaign: referralUtmCampaign } =
    queryParams

  const query = new URLSearchParams()
  if (referralUtmSource) query.set("utm_source", referralUtmSource)
  if (referralUtmCampaign) query.set("utm_campaign", referralUtmCampaign)

  if (query.toString()) {
    setReferralUtms({ referralUtmSource, referralUtmCampaign })
  }

  queryGetStarted = query.toString()
    ? `/get-started?${query.toString()}`
    : "/get-started"

  page =
    $registrationDisabled ||
    ($externalLogin && partnerLandingPage.CTAUrl && !$isLoggedIn)
      ? partnerLandingPage.CTAUrl
      : queryGetStarted
})
</script>

<FullLayout>
  <section class="hero">
    <div class="flex flex-col w-full max-w-3xl px-4 mx-auto background-text">
      <div class="flex flex-col items-center justify-center flex-1 text-center">
        <h2 class="w-full mb-6 text-4xl font-semibold text-center md:text-6xl">
          {#if referralLandingPage.bannerHeading}
            <PageTemplate content="{referralLandingPage.bannerHeading}" />
          {:else}
            <PageTemplate content="{partnerLandingPage.bannerHeading}" />
          {/if}
        </h2>
        <h3 class="mb-6 text-xl md:text-3xl">
          {#if referralLandingPage.bannerSubHeading}
            <PageTemplate content="{referralLandingPage.bannerSubHeading}" />
          {:else}
            <PageTemplate content="{partnerLandingPage.bannerSubHeading}" />
          {/if}
        </h3>
        <h3 class="mb-6 text-lg md:text-2xl">
          <PageTemplate content="{referralPage.bannerSubHeadingCta}" />
        </h3>
        <div class="mx-auto flex space-x-4">
          {#if referralLandingPage.secondaryCTA}
            <Button
              type="button"
              on:click="{() => navigate(referralLandingPage.secondaryCTAUrl)}"
              color="white"
              label="{referralLandingPage.secondaryCTA}"
            />
            <Button
              type="button"
              on:click="{() => navigate(page)}"
              color="primary"
              label="{referralLandingPage.bannerCTA}"
            />
          {:else if partnerLandingPage.secondaryCTA}
            <Button
              type="button"
              on:click="{() => navigate(partnerLandingPage.secondaryCTAUrl)}"
              color="white"
              label="{partnerLandingPage.secondaryCTA}"
            />
            <Button
              type="button"
              on:click="{() => navigate(page)}"
              color="primary"
              label="{partnerLandingPage.bannerCTA}"
            />
          {:else}
            <Button
              type="button"
              on:click="{() => navigate(page)}"
              color="white"
              label="{referralLandingPage.bannerCTA
                ? referralLandingPage.bannerCTA
                : partnerLandingPage.bannerCTA}"
            />
          {/if}
        </div>
      </div>
      <div
        class="flex flex-col items-center justify-center mx-auto mb-8 md:mb-24"
        on:click="{scrollFromHero}"
      >
        <i class="cursor-pointer icon material-icons hover:opacity-75">
          arrow_downward
        </i>
        <span class="text-2xl">{learnMoreText[$workflowLang]}</span>
      </div>
    </div>
  </section>

  <section id="icons-section" class="px-8 py-16 bg-white md:py-24">
    <div class="w-full max-w-screen-xl mx-auto text-center">
      <h3 class="mb-12 text-3xl font-semibold md:text-4xl md:mb-20">
        {#if referralLandingPage.stepsHeading}
          <PageTemplate content="{referralLandingPage.stepsHeading}" />
        {:else}
          <PageTemplate content="{partnerLandingPage.stepsHeading}" />
        {/if}
      </h3>
      <div class="grid justify-between gap-8 md:flex-row md:grid-cols-3">
        <div class="flex flex-col items-center justify-center mb-8 md:mb-16">
          <img
            class="w-24 h-24 mb-8"
            alt=""
            src="/images/welcome/icon-register.svg"
          />
          <p class="text-base md:text-lg">
            {#if referralLandingPage.step1Text}
              <PageTemplate content="{referralLandingPage.step1Text}" />
            {:else}
              <PageTemplate content="{partnerLandingPage.step1Text}" />
            {/if}
          </p>
        </div>
        <div class="flex flex-col items-center justify-center mb-8 md:mb-16">
          <img
            class="w-24 h-24 mb-8"
            alt=""
            src="/images/welcome/icon-talk.svg"
          />
          <p class="text-base md:text-lg">
            {#if referralLandingPage.step2Text}
              <PageTemplate content="{referralLandingPage.step2Text}" />
            {:else}
              <PageTemplate content="{partnerLandingPage.step2Text}" />
            {/if}
          </p>
        </div>
        <div class="flex flex-col items-center justify-center mb-8 md:mb-16">
          <img
            class="w-20 h-20 mb-8"
            alt=""
            src="/images/welcome/icon-card.svg"
          />
          <p class="text-base md:text-lg">
            {#if referralLandingPage.step3Text}
              <PageTemplate content="{referralLandingPage.step3Text}" />
            {:else}
              <PageTemplate content="{partnerLandingPage.step3Text}" />
            {/if}
          </p>
        </div>
      </div>
      <div class="mx-auto">
        <Button
          type="button"
          on:click="{() => navigate(page)}"
          color="primary"
          label="{referralLandingPage.stepsCTA
            ? referralLandingPage.stepsCTA
            : partnerLandingPage.stepsCTA}"
        />
      </div>
    </div>
  </section>
  <section class="flex flex-col w-full mx-auto max-w-screen-2xl md:flex-row">
    <div class="h-64 md:w-1/2 md:h-auto">
      <div class="center-image"></div>
    </div>
    <div class="px-4 py-24 md:px-32 md:w-1/2 text-{$infoSectionFontColor}">
      <h3 class="mb-12 text-4xl font-bold text-center uppercase">
        {#if referralLandingPage.infoHeading}
          <PageTemplate content="{referralLandingPage.infoHeading}" />
        {:else}
          <PageTemplate content="{partnerLandingPage.infoHeading}" />
        {/if}
      </h3>
      <p class="mb-12 text-base text-center">
        {#if referralLandingPage.infoText}
          <PageTemplate content="{referralLandingPage.infoText}" />
        {:else}
          <PageTemplate content="{partnerLandingPage.infoText}" />
        {/if}
      </p>
      <div class="mx-auto">
        <Button
          type="button"
          on:click="{() => navigate(page)}"
          color="primary"
          label="{referralLandingPage.infoCTA
            ? referralLandingPage.infoCTA
            : partnerLandingPage.infoCTA}"
        />
      </div>
    </div>
  </section>

  <section class="px-8 py-16 bg-white md:py-24">
    <div class="w-full max-w-screen-xl mx-auto">
      <h3 class="mb-8 text-3xl font-semibold md:text-4xl md:mb-16">
        {generalQuestionsText[$workflowLang]}
      </h3>
      <ul class="mb-16">
        {#each faqItems as { question, answer }, i}
          <li class="pb-8 mb-8 border-b border-gray-700">
            <div
              class="flex items-center cursor-pointer hover:opacity-75"
              on:click="{() => openQuestion(i)}"
            >
              <h4
                class="font-semibold"
                class:question-primary="{openedQuestion === i}"
              >
                <PageTemplate content="{question}" />
              </h4>
              <i class="arrow" class:arrow-opened="{openedQuestion === i}"></i>
            </div>
            <p
              class="hidden mt-8"
              class:answer-visible="{openedQuestion === i}"
            >
              <PageTemplate content="{answer}" />
            </p>
          </li>
        {/each}
      </ul>

      <div class="w-auto mx-auto">
        <Button
          type="button"
          on:click="{() => navigate(page)}"
          color="primary"
          label="{referralLandingPage.faqCTA
            ? referralLandingPage.faqCTA
            : partnerLandingPage.faqCTA}"
        />
      </div>
    </div>
  </section>

  <section
    class="flex flex-col justify-between w-full max-w-screen-xl px-4 py-8 mx-auto md:flex-row"
  >
    <div>
      <img
        alt="{$logoAlt}"
        class="w-auto h-16 mb-8 md:mb-0"
        src="/images/hellomd/logo.svg"
      />
    </div>
    <div class="flex text-sm text-{$linkSectionFontColor}">
      <div class="mr-12">
        <h3 class="mb-2 font-semibold uppercase">
          {usefulLinks[$workflowLang]}
        </h3>
        <ul>
          <li class="mb-2">
            <a href="{learnMoreUrl}">{learnMoreText[$workflowLang]}</a>
          </li>
          <li class="mb-2">
            <a href="{privacyPolicyUrl}">{privacyPolicyText[$workflowLang]}</a>
          </li>
        </ul>
      </div>
      <div>
        <h3 class="mb-2 font-semibold uppercase">{contactUs[$workflowLang]}</h3>
        <p><a href="mailto:support@hellomd.com">support@hellomd.com</a></p>
      </div>
    </div>
  </section>

  <footer class="bar">
    <div
      class="max-w-screen-xl px-4 py-6 mx-auto text-xs font-light tracking-wide text-white"
    >
      <p>© {new Date().getFullYear()} {allRightsReservedText[$workflowLang]}</p>
    </div>
  </footer>
</FullLayout>

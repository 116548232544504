<script>
import { onMount, onDestroy } from "svelte"
import { navigate } from "svelte-routing"
import socketio from "socket.io-client"

import {
  getPatientWorkflowById,
  patchPatientWorkflowById,
  backPatientWorkflow,
  patchLanguage,
} from "@local/config/datasource"
import { apiBasePath } from "@local/config/constants"
import { auth, getAccessToken, currentUser } from "@local/store/auth"
import Render from "@local/components/Render.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import { partner } from "@local/store/theme"

export let workflowId = undefined

let elements = []
let payload = {}
let errors = {}
let prettyUrl = ""
let subdomain = ""
$: subdomain = $partner.origin.subdomain

$: {
  if ($currentUser.language !== $workflowLang) {
    console.info(`Switching language to ${$workflowLang}`)
    updateUserLanguage($workflowLang)
    load()
  }
}

// Activity ID: 13618787
function startingWFConversion() {
  if (subdomain === "illinois5") {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag("js", new Date())
    gtag("config", "DC-12569754")
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-12569754/conv30/step20+unique",
    })
  }
}

// Activity ID: 13589422
function paymentConfirmationConversion() {
  if (subdomain === "illinois5") {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag("js", new Date())
    gtag("config", "DC-12569754")
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-12569754/conv30/step80+unique",
    })
  }
}

async function submit(e) {
  const formNode = e.currentTarget
  const buttonNode = formNode.querySelector("button")

  buttonNode && (buttonNode.disabled = true)

  errors = {}
  try {
    const data = await patchPatientWorkflowById(workflowId, payload)

    if (prettyUrl === "/payment") {
      paymentConfirmationConversion()
    }

    elements = data.elements
    payload = data.payload
    prettyUrl = data.prettyUrl
  } catch (err) {
    errors = err
    const firstField = Object.keys(errors)[0]

    if (!Array.isArray(firstField)) {
      const el = document.querySelector(`#${firstField}`)
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" })
        return
      }
    }

    // give a last chance and avoid scrolling to page top at all cost
    const firstInputNode = formNode.querySelector("input")
    if (firstInputNode) {
      firstInputNode.scrollIntoView({ behavior: "smooth", block: "start" })
      return
    }
  } finally {
    buttonNode && (buttonNode.disabled = false)
  }

  window.scroll({ top: 0, behavior: "smooth" })
}

async function load() {
  elements = []
  const data = await getPatientWorkflowById(workflowId)
  elements = data.elements
  payload = data.payload
  prettyUrl = data.prettyUrl

  if (prettyUrl === "/basic-info") {
    startingWFConversion()
  }
}

async function updateUserLanguage(language) {
  const partnerId = $currentUser.partnerId
  const userId = $currentUser.id

  try {
    const tokens = await patchLanguage({ partnerId, userId, language })
    auth(tokens)
  } catch (err) {
    console.error("Unable to save language preferences", err)
  }
}

async function connectWs() {
  const jwt = await getAccessToken()
  socket = socketio(apiBasePath, { query: { jwt }, transports: ["websocket"] })

  socket.on("connect", () => {
    socket.emit("observe", `user:${$currentUser.id}`)
  })

  socket.on("error", (err) => {
    console.log(err)
  })

  socket.on("reload", () => {
    load()
  })
}

onMount(load)
onMount(connectWs)

onDestroy(function () {
  if (socket) {
    socket.disconnect()
  }
})

let socket

function updateUrl(prettyUrl) {
  if (prettyUrl) {
    navigate(`/workflows/${workflowId}${prettyUrl}`, { replace: true })
  } else {
    navigate(`/workflows/${workflowId}`, { replace: true })
  }
  if (window.Intercom) {
    window.Intercom("update")
  }
}

$: updateUrl(prettyUrl)

const actions = {
  async back(amount = 1) {
    const data = await backPatientWorkflow(workflowId, amount)
    elements = data.elements
    payload = data.payload
    window.scrollTo(0, 0)
  },
}
</script>

<form
  on:submit|preventDefault
  class="w-full"
  autocomplete="off"
  on:submit="{submit}"
>
  {#each elements as element}
    <Render
      actions="{actions}"
      element="{element}"
      allErrors="{errors}"
      bind:payload="{payload}"
    />
  {/each}
</form>

<!--
  1st img
  Event snippet for Step 3 - Conversion - Validation on https://illinois5.hellomd.com/workflows/basic-info: Please do not remove.
  Place this snippet on pages with events you’re tracking.
  Creation date: 12/15/2022

  2nd img
  Event snippet for Step 4 - Conversion - Payment Confirmation - Thank You Page on https://illinois5.hellomd.com/workflows/payment: Please do not remove.
  Place this snippet on pages with events you’re tracking.
  Creation date: 12/15/2022
-->
{#if subdomain === "illinois5"}
  <noscript>
    <img
      src="https://ad.doubleclick.net/ddm/activity/src=12569754;type=conv30;cat=step20;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?"
      width="1"
      height="1"
      alt=""
    />

    <img
      src="https://ad.doubleclick.net/ddm/activity/src=12569754;type=conv30;cat=step80;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?"
      width="1"
      height="1"
      alt=""
    />
  </noscript>
{/if}
<!-- End of event snippet: Please do not remove -->

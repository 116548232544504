<style>
h1 {
  @apply leading-tight tracking-tight text-center whitespace-pre-wrap text-4xl font-semibold;
}
.font-size-sm {
  @apply text-sm;
}
.font-size-normal {
  @apply text-base;
}
.font-size-lg {
  @apply text-lg;
}
.font-size-xl {
  @apply text-xl;
}
.font-size-2xl {
  @apply text-2xl;
}
.font-size-3xl {
  @apply text-3xl;
}
.font-weight-normal {
  @apply font-normal;
}
.font-weight-semibold {
  @apply font-semibold;
}
.font-weight-bold {
  @apply font-bold;
}
.text-align-center {
  @apply text-center;
}
.text-align-left {
  @apply text-left;
}
.text-align-right {
  @apply text-right;
}
@screen md {
  h1 {
    @apply -mx-12;
  }
}
@screen lg {
  h1 {
    @apply -mx-24;
  }
}
</style>

<script>
export let className = ""
export let content = ""
</script>

<h1 class="{className}">
  {@html content}
</h1>

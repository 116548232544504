<style>
span {
  @apply underline cursor-pointer;
  color: var(--primary);
}
button {
  @apply border rounded-md;
  @apply px-4 py-2;
  @apply text-white bg-black;
  background-color: var(--primary);
}
button:hover {
  opacity: 0.95;
}
</style>

<script>
import API from "@local/utils/api"
import TextInput from "./TextInput.svelte"
export let wfId = undefined
export let amount = undefined
export let coupon = {}
export let couponCode = ""
let internalCouponCode = ""
let open = false

async function apply() {
  try {
    const sanitizedCode = `${internalCouponCode}`.trim().toUpperCase()
    coupon = await API.get(`payments/${wfId}/coupon`, {
      searchParams: {
        role: "patient",
        couponCode: sanitizedCode,
        amount,
      },
    }).json()
    couponCode = sanitizedCode
  } catch (err) {
    coupon = {}
    couponCode = ""
    console.log(err)
    alert("This coupon code is either invalid or expired.")
  }
}
</script>

<div class="flex justify-end {$$props.class || ''}">
  {#if !coupon.couponCode}
    {#if !open}
      <span on:click="{() => (open = true)}">I have a coupon code</span>
    {:else}
      <div class="flex flex-row gap-2">
        <div class="w-40">
          <TextInput
            bind:value="{internalCouponCode}"
            placeholder="Coupon Code"
          />
        </div>
        <button type="button" on:click="{apply}">Apply</button>
      </div>
    {/if}
  {/if}
</div>

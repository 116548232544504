<style>
a {
  @apply cursor-pointer uppercase text-left inline;
}
a:hover {
  @apply underline;
}
img {
  @apply h-10 ml-2;
}
</style>

<script>
export let label = ""
export let url = ""
export let className = ""
export let icon = ""
export let newWindow = true
</script>

{#if newWindow}
  <a target="_blank" href="{url}" class="{className}">
    <div class="flex flex-start">
      {#if icon}<img src="/images/{icon}" alt="{icon}" />{/if}
      <div class="ml-2">{label}</div>
    </div>
  </a>
{:else}
  <a href="{url}" class="{className}">
    <div class="flex flex-start">
      {#if icon}<img src="/images/{icon}" alt="{icon}" />{/if}{label}
    </div>
  </a>
{/if}

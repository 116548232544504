<style>
.link.primary {
  color: var(--primary);
}

.link.black {
  color: black;
}
</style>

<script>
import API from "@local/utils/api"
import Icon from "@shared/components/Icon.svelte"

export let label = ""
export let url = ""
export let enableConfirmation = false
export let confirmationMessage = "Are you sure?"
export let className = ""
export let icon = undefined
export let role = "patient"
export let reload = false
let submitting = false

async function click() {
  if (enableConfirmation) {
    const confirmation = confirm(confirmationMessage)
    if (!confirmation) {
      return
    }
  }
  try {
    const searchParams = {
      role,
      render: false,
    }
    submitting = true

    await API.patch(url, {
      json: {},
      searchParams,
    }).json()

    if (reload) {
      window.location.reload()
    }
  } catch (err) {
    alert("Failed: " + err)
  } finally {
    submitting = false
  }
}
</script>

<span
  on:click="{click}"
  target="_blank"
  class="{`block cursor-pointer ${className}`}"
>
  {#if icon}
    <Icon class="mr-2 align-middle">{icon}</Icon>
  {/if}
  {label}
</span>

<!-- <Button {...$$restProps} on:click="{click}" type="button" /> -->

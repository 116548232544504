<style>
.edit-link {
  @apply inline-block cursor-pointer mx-auto font-semibold;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
  line-height: 24px;
}
.edit-link:hover {
  @apply underline;
}
</style>

<script>
export let actions = {}
export let amount = 1
export let className = ""
</script>

<div class="edit-link {className}" on:click="{() => actions.back(amount)}">
  Edit
</div>

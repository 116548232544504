<style>
.main :global(h1) {
  @apply my-4 text-3xl font-bold;
}
.main :global(h2) {
  @apply my-4 text-xl font-semibold;
}
.main :global(p) {
  @apply mb-4;
}
.main :global(a) {
  @apply underline text-blue-400;
}
.main :global(ol) {
  @apply list-decimal pl-6;
}
.main :global(ul) {
  @apply list-disc pl-6;
}
.main :global(li:not(:last-child)) {
  @apply mb-2;
}
</style>

<script>
</script>

<div class="flex w-full max-w-screen-xl mx-auto py-8">
  <div class="main bg-white rounded-md flex-1 py-2 px-4">
    <slot />
  </div>
</div>

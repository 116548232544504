<style>
.addMore {
  @apply flex items-center mt-6 cursor-pointer;
  font-size: 15px;
  color: var(--primary);
}
.addMore:hover {
  @apply opacity-75;
}
.loading {
  @apply cursor-wait;
}
.remove {
  @apply absolute flex items-center text-red-600 cursor-pointer;
  font-size: 15px;
  right: 16px;
}
.hint {
  @apply text-center px-4 mt-4 mb-4;
  font-size: 15px;
  letter-spacing: -0.36px;
  line-height: 1.4;
}
.error {
  @apply my-2 text-center;
  color: #c4272a;
}
</style>

<script>
import Button from "./Button.svelte"
import SubHeading from "./SubHeading.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  removeText,
  addAnotherText,
  loadingText,
  errorUploadingFileText,
  errorRemovingFileText,
  errorUploadingFileWithSameNameText,
} from "@shared/utils/translations"
import API from "@local/utils/api"

let _errors

export let value = undefined
export let label = ""
export let hint = ""
export let buttonHint = ""
export let multi = false
export let buttonLabel = ""
export let buttonColor = undefined
export let role = undefined
export let name = ""
export let wfId = undefined
export let userId = undefined
export let allowedExtension = undefined
export let files = []
export { _errors as errors }

let uploadInput

$: loading = false
$: errors = _errors || []
$: hasErrors = errors.length > 0
$: hasFile = files.length > 0

async function onChange(evt = {}) {
  try {
    loading = true

    const file = evt.target.files[0]
    const blob = await file.arrayBuffer()
    const contentType = file.type
    const fileName = file.name.substr(0, file.name.lastIndexOf("."))

    const fileNameAlreadyExists = files.find(
      (file) => file.split("/").pop() === fileName
    )

    if (fileNameAlreadyExists) {
      const errorMsg = errorUploadingFileWithSameNameText[$workflowLang]
      errors = [...errors, errorMsg]
      value = files
      return
    }

    const { uploadUrl, url } = await API.post(`uploads/${wfId}`, {
      searchParams: { role: role },
      json: {
        field: name,
        fieldContentType: contentType,
        userId,
        fileName,
      },
    }).json()

    await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: { "Content-Type": contentType },
    })

    files = multi ? [...files, url] : [file]
    value = files
    evt.target.value = ""
  } catch (err) {
    let errorMsg = errorUploadingFileText[$workflowLang]
    errors = [...errors, errorMsg]
  } finally {
    loading = false
  }
}

async function remove(filePath) {
  try {
    loading = true

    await API.delete(`uploads/${wfId}`, {
      searchParams: { role: role },
      json: {
        field: name,
        userId,
        path: filePath,
      },
    })

    files = files.filter((file) => file !== filePath)
    value = files
  } catch (err) {
    let errorMsg = errorRemovingFileText[$workflowLang]
    errors = [...errors, errorMsg]
  } finally {
    loading = false
  }
}
</script>

<div class="{$$props.className}" id="{name}">
  {#if label}
    <SubHeading className="mt-6 mb-8" content="{label}" />
  {/if}

  {#if hint}
    <div class="hint">{hint}</div>
  {/if}

  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}

  <input
    bind:value="{value}"
    bind:this="{uploadInput}"
    on:change="{onChange}"
    type="file"
    class="hidden"
    accept="{allowedExtension.join(',')}"
  />
  {#if !multi || !hasFile}
    {#if loading}
      <Button
        type="button"
        label="{loadingText[$workflowLang]}"
        hint="{buttonHint}"
        disabled="{loading}"
      />
    {:else}
      <Button
        type="button"
        label="{buttonLabel}"
        color="{buttonColor}"
        hint="{buttonHint}"
        on:click="{() => uploadInput.click()}"
      />
    {/if}
  {/if}

  {#each files as file}
    <div class="relative mt-8">
      <span class="remove" on:click="{() => remove(file)}">
        <i class="icon material-icons mr-2"> cancel </i>
        {removeText[$workflowLang]}
      </span>

      <p class="max-w-xs">{file.split("/").pop()}</p>
    </div>
  {/each}

  {#if multi && hasFile}
    {#if loading}
      <span class="addMore loading">
        <i class="icon material-icons mr-2"> hourglass_empty </i>
        {loadingText[$workflowLang]}
      </span>
    {:else}
      <span class="addMore" on:click="{() => uploadInput.click()}">
        <i class="icon material-icons mr-2"> add_circle </i>
        {addAnotherText[$workflowLang]}
      </span>
    {/if}
  {/if}
</div>

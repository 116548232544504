<script>
export let accountId = ""
export let affiliateId = ""
export let amount = ""
export let wfId = ""
</script>

<img
  src="https://hellomd.postaffiliatepro.com/scripts/hzsgo5?AccountId={accountId}&TotalCost={amount}&OrderID={wfId}&ProductID=Consultation&AffiliateID={affiliateId}"
  width="1"
  height="1"
  alt="pap"
/>

<script>
import differenceInDays from "date-fns/differenceInDays"

import autocomplete from "./Autocomplete.svelte"
import actionLink from "./ActionLink.svelte"
import buttonLink from "./ButtonLink.svelte"
import buttonFileLink from "./ButtonFileLink.svelte"
import backLink from "./BackLink.svelte"
import box from "./Box.svelte"
import breadcrumb from "./Breadcrumb.svelte"
import centeredParagraph from "./CenteredParagraph.svelte"
import checkBoxes from "./CheckBoxes.svelte"
import checkButtons from "./CheckButtons.svelte"
import dataButton from "./DataButton.svelte"
import datePicker from "./DatePicker.svelte"
import div from "./Div.svelte"
import downloadLink from "./DownloadLink.svelte"
import downloadFileList from "./DownloadFileList.svelte"
import editLink from "./EditLink.svelte"
import externalActionLink from "./ExternalActionLink.svelte"
import grid from "./Grid.svelte"
import groupElements from "./GroupElements.svelte"
import heading from "./Heading.svelte"
import horizontalLine from "./HorizontalLine.svelte"
import image from "./Image.svelte"
import labelData from "./LabelData.svelte"
import maskInput from "./MaskInput.svelte"
import modal from "./Modal.svelte"
import multiSelectInput from "./MultiSelectInput.svelte"
import multiAutocomplete from "./MultiAutocomplete.svelte"
import medicine from "./Medicine.svelte"
import multiTextInput from "./MultiTextInput.svelte"
import numberInput from "./NumberInput.svelte"
import oneTwoLayout from "./OneTwoLayout.svelte"
import page from "./Page.svelte"
import paragraph from "./Paragraph.svelte"
import payment from "./Payment.svelte"
import paymentTracker from "./PaymentTracker.svelte"
import quantityPerDispensary from "./QuantityPerDispensary.svelte"
import radio from "./Radio.svelte"
import redirect from "./Redirect.svelte"
import reference from "./Reference.svelte"
import schedule from "./Schedule.svelte"
import scheduleConfirmation from "./ScheduleConfirmation.svelte"
import select from "./Select.svelte"
import subHeading from "./SubHeading.svelte"
import submit from "./Submit.svelte"
import tabbedNav from "./TabbedNav.svelte"
import takeScreenshot from "./TakeScreenshot.svelte"
import textArea from "./TextArea.svelte"
import textInput from "./TextInput.svelte"
import twoLayout from "./TwoLayout.svelte"
import twoOneLayout from "./TwoOneLayout.svelte"
import threeLayout from "./ThreeLayout.svelte"
import upload from "./Upload.svelte"
import uploader from "./Uploader.svelte"
import videoRoom from "./VideoRoom.svelte"
import videoRoomDoctor from "./VideoRoomDoctor.svelte"
import videoTest from "./VideoTest.svelte"
import warning from "./Warning.svelte"
import separator from "./Separator.svelte"
import alert from "./Alert.svelte"
import questionnaire from "./Questionnaire.svelte"
import affiliateTracker from "./AffiliateTracker.svelte"
import stackAdaptTracker from "./StackAdaptTracker.svelte"
import buttonValue from "./ButtonValue.svelte"

export let actions = undefined
export let element = undefined
export let payload = undefined
export let allErrors = undefined

const components = {
  autocomplete,
  actionLink,
  backLink,
  box,
  breadcrumb,
  buttonLink,
  buttonFileLink: buttonFileLink,
  centeredParagraph,
  checkBoxes,
  checkButtons,
  dataButton,
  datePicker,
  div,
  downloadLink,
  downloadFileList,
  editLink,
  externalActionLink,
  grid,
  groupElements,
  heading,
  horizontalLine,
  image,
  labelData,
  maskInput,
  modal,
  multiSelectInput,
  multiAutocomplete,
  medicine,
  multiTextInput,
  numberInput,
  oneTwoLayout,
  page,
  paragraph,
  payment,
  paymentTracker,
  quantityPerDispensary,
  radio,
  redirect,
  reference,
  schedule,
  scheduleConfirmation,
  select,
  subHeading,
  submit,
  tabbedNav,
  takeScreenshot,
  textArea,
  textInput,
  threeLayout,
  twoLayout,
  twoOneLayout,
  upload,
  uploader,
  warning,
  videoRoom,
  videoRoomDoctor,
  videoTest,
  separator,
  alert,
  questionnaire,
  affiliateTracker,
  stackAdaptTracker,
  buttonValue,
}

const operations = {
  equals: (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      return JSON.stringify(a) === JSON.stringify(b)
    }
    return a === b
  },
  notEquals: (a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      return JSON.stringify(a) !== JSON.stringify(b)
    }
    return a !== b
  },
  gte: (a, b) => a >= b,
  lengthGt: (a, b) => (a || []).length > b,
  includes: (a, b) => (a || []).includes(b),
  notIncludes: (a, b) => !(a || []).includes(b),
  isDefined: (a, _b) => a !== undefined && a !== null,
  notDefined: (a, _b) => a === undefined || a === null,
  daysFuture: (a, b) => differenceInDays(a, new Date()) >= b,
  daysPast: (a, b) => differenceInDays(new Date(), a) >= b,
  scheduleFuture: (a, b) => {
    if (typeof a === "string" && a.includes("~")) {
      a = a.split("~")[0]
    }
    return differenceInDays(new Date(a), new Date()) >= b
  },
  minutesFuture: (a, b) => {
    if (b === null) return false
    return differenceInMinutes(new Date(a), new Date()) >= b
  },
}

function checkDependsOn(element, payload = {}) {
  if (!element?.props?.dependsOn) return true
  for (let dependency of element?.props?.dependsOn) {
    const { propKey, operator, value } = dependency
    const operation = operations[operator]
    if (!operation(payload[propKey], value)) return false
  }
  return true
}
</script>

{#if element}
  {#if checkDependsOn(element, payload)}
    <svelte:component
      this="{components[element?.type]}"
      {...element?.props}
      allErrors="{allErrors}"
      actions="{actions}"
      errors="{element?.props?.name && allErrors[element?.props?.name]}"
      bind:payload="{payload}"
      bind:value="{payload[element?.props?.name]}"
    />
  {/if}
{/if}

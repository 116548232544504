<style>
.wrapper {
  @apply w-full relative;
}
.input-wrapper {
  @apply w-full border-0 border-b-4 rounded relative;
  background-color: #f3f3f3;
  border-color: #757575;
}
.input-wrapper.disabled {
  background-color: #dcdcdc;
  border-color: #9b9b9b;
}
.input-wrapper.focused {
  border-color: var(--primary);
}
.input-wrapper.has-errors {
  border-color: #c4272a;
}
.input {
  @apply w-full bg-transparent border-0 outline-none pt-6 pb-1 text-black text-lg px-4 ease-in duration-200 appearance-none;
  line-height: 1.5rem;
}
.input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.label {
  @apply w-full text-lg pt-4 pb-4 pl-4 pr-8 ease-in duration-100 absolute top-0 truncate pointer-events-none;
  color: #9b9b9b;
  line-height: 1.25rem;
}
.label.disabled {
  color: #757575;
}
.label.expanded {
  @apply pb-1 pt-2 text-xs leading-none;
}
.label.focused {
  @apply pointer-events-none;
  color: var(--primary);
}

.datalist {
  @apply bg-white shadow-xl pt-2;
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 465px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
  max-height: 27.5rem;
}
option {
  @apply flex items-center py-2 px-4 cursor-pointer;
}
option:hover {
  background-color: #f3f3f3;
}
.caret,
.loop {
  @apply flex justify-center items-center absolute top-0 right-0 h-13 w-8 text-lg;
  color: #757575;
}
.caret.focused {
  @apply transform rotate-180 duration-300 ease-in;
  color: var(--primary);
}

.clickable {
  cursor: pointer;
}

.loop {
  right: 25px;
}

.animation-loop {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>

<script>
import { createEventDispatcher } from "svelte"
import { getMedicines } from "@local/config/datasource"

export let name = ""
export let label = "Medicine"
export let icon = undefined
export let iconClickable = undefined

export let options = []
export let disabled = false
export let value = undefined
export let className = ""

const dispatch = createEventDispatcher()

let inputEl

let focused = false
$: expanded = value || focused
$: hasIcon = !!icon

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function focus() {
  focused = true
}

function blur() {
  focused = false
}

async function loadMedications() {
  try {
    return await getMedicines()
  } catch (e) {
    console.log(e)
  }
}

options = loadMedications().then((medicines) => {
  options = medicines
})
</script>

<div class="wrapper {className}">
  <div class="flex items-center">
    <div class="input-wrapper" class:focused>
      <label
        for="{name}"
        class="label"
        class:focused
        class:expanded
        class:disabled
      >{label}</label>
      <input
        bind:this="{inputEl}"
        type="text"
        name="{name}"
        list="{name}-list"
        class="input"
        on:focus="{focus}"
        on:blur="{blur}"
        bind:value
        disabled="{disabled}"
      />
      <datalist class="datalist" id="{name}-list">
        {#if options && options.length > 0}
          {#each options as option, i}
            <option value="{option.value || option}">
              {option.label || option}
            </option>
          {/each}
        {/if}
      </datalist>
      {#if !options}
        <i
          class="loop material-icons animation-loop"
          class:focused
          class:disabled
        >
          loop
        </i>
      {/if}
      <i class="caret material-icons" class:focused class:disabled>
        arrow_drop_down
      </i>
    </div>
    {#if hasIcon}
      <i
        class="mx-2 icon material-icons"
        class:clickable="{iconClickable}"
        on:click="{iconClicked}"
      >
        {icon}
      </i>
    {/if}
  </div>
</div>

<style>
.wrapper {
  @apply w-full;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import { onMount } from "svelte"
import IMask from "imask"
import TextInput from "@shared/components/TextInput.svelte"

export let name = undefined
export let label = undefined
export let value = undefined
export let className = ""

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

let focused = false
$: expanded = value || focused
$: error = !focused && hasErrors

export let mask = undefined
export let letterCase = undefined
let inputEl
let imask
$: inputEl, mask, handleMaskOpts()
function handleMaskOpts() {
  if (!mask || !inputEl) return imask && imask.destroy()
  if (imask) return imask.updateOptions({ mask })

  imask = letterCase
    ? new IMask(inputEl, {
        mask,
        prepare: function (value) {
          if (value) {
            if (letterCase === "upperCase") return value.toUpperCase()
            if (letterCase === "lowerCase") return value.toLowerCase()
          }
          return value
        },
      })
    : new IMask(inputEl, { mask })
  imask.on("accept", () => (value = imask.value))
}
</script>

<div class="wrapper {className}">
  <TextInput
    name="{name}"
    label="{label}"
    hasErrors="{hasErrors}"
    value="{value}"
    bind:inputEl
  />
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
</div>

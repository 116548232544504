<script>
import { onMount } from "svelte"

export let delayMs = null
let timeout

window.intercomSettings = {
  app_id: "SVELTE_APP_INTERCOM_ID",
}

function loadIntercom() {
  var w = window
  var ic = w.Intercom
  if (typeof ic === "function") {
    ic("reattach_activator")
    ic("update", w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var s = d.createElement("script")
    s.type = "text/javascript"
    s.async = true
    s.src = "https://widget.intercom.io/widget/SVELTE_APP_INTERCOM_ID"
    var x = d.getElementsByTagName("script")[0]
    x.parentNode && x.parentNode.insertBefore(s, x)
  }
}

onMount(() => {
  if (delayMs) {
    timeout = setTimeout(() => {
      loadIntercom()
    }, delayMs)
  }

  return () => clearTimeout(timeout)
})
</script>

<div></div>

<script>
export let wfId
export let partner
export let totalAmount
export let couponDiscount

window.dataLayer = window.dataLayer || []

function gtag() {
  dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: wfId,
          affiliation: partner,
          revenue: totalAmount,
          coupon: couponDiscount,
        },
        products: [
          {
            name: "Consultation",
            price: totalAmount,
            quantity: 1,
            coupon: couponDiscount,
          },
        ],
      },
    },
  })
}

gtag("event", "purchase")
</script>

<script>
import { onMount, tick } from "svelte"
import queryString from "query-string"
import { navigate } from "svelte-routing"

import {
  postConfirmationEmails,
  patchIsEmailConfirmed,
} from "@local/config/datasource"
import { partner as _partner } from "@local/store/theme"
import { partnerId as _partnerId } from "@local/store/theme"
import { auth, currentUser, isEmailConfirmed } from "@local/store/auth"

import TwoLayout from "@shared/components/TwoLayout.svelte"
import Heading from "@local/components/Heading.svelte"
import SubHeading from "@local/components/SubHeading.svelte"
import CenteredParagraph from "@local/components/CenteredParagraph.svelte"
import Button from "@local/components/Button.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  verifyEmailHeadingText,
  verifyEmailSubHeadingText,
  notSeeingItText,
  resendText,
  sentText,
  emailSentText,
  sorryVerificationFailedText,
  pleaseClickResendText,
} from "@shared/utils/translations"
import { referralUtms as _referralUtms } from "@local/store/referralUtms"

export let location = {}
export let token
let referralUtmSource
let referralUtmCampaign

let resent = false
let queryParams
let errorPage = false

$: queryParams = queryString.parse(location.search)
$: next = queryParams.next
$: referralUtmSource = queryParams.utm_source
$: referralUtmCampaign = queryParams.utm_campaign

$: partner = $_partner
$: partnerId = $_partnerId
$: email = $currentUser && $currentUser.email

onMount(async function () {
  if (token) {
    if (!referralUtmSource)
      referralUtmSource = $_referralUtms?.referralUtmSource
    if (!referralUtmCampaign)
      referralUtmCampaign = $_referralUtms?.referralUtmCampaign

    const query = new URLSearchParams()
    if (referralUtmSource) query.set("utm_source", referralUtmSource)
    if (referralUtmCampaign) query.set("utm_campaign", referralUtmCampaign)

    const queryString = query.toString().trim()

    try {
      const response = await patchIsEmailConfirmed({
        partnerId,
        token,
      })
      auth(response)
      await tick()

      const nextPath = `${next}${queryString ? `?${queryString}` : ""}`

      navigate(nextPath, { replace: true })
    } catch (err) {
      if ($isEmailConfirmed) {
        const nextPath = `/${queryString ? `?${queryString}` : ""}`

        navigate(nextPath, { replace: true })
      } else {
        errorPage = true
      }
    }
  }
})

async function resend() {
  if (!referralUtmSource) {
    referralUtmSource = $_referralUtms?.referralUtmSource
  }
  if (!referralUtmCampaign) {
    referralUtmCampaign = $_referralUtms?.referralUtmCampaign
  }
  resent = true
  errorPage = false
  await postConfirmationEmails({
    next,
    partnerId,
    email,
    partnerOrigin: partner.origin.subdomain,
    referralUtms: { referralUtmSource, referralUtmCampaign },
  })
}
</script>

<TwoLayout>
  {#if errorPage}
    <Heading
      content="{sorryVerificationFailedText[$workflowLang]}"
      className="mb-12"
    />
  {:else}
    <Heading
      content="{verifyEmailHeadingText[$workflowLang]}"
      className="mb-12"
    />
    <SubHeading
      content="{verifyEmailSubHeadingText[$workflowLang]}"
      className="mb-12"
    />
  {/if}
  <img
    src="/images/mail.svg"
    alt="{emailSentText[$workflowLang]}"
    class="mb-12"
  />
  {#if errorPage}
    <CenteredParagraph
      content="{pleaseClickResendText[$workflowLang]}"
      className="mb-2"
    />
  {:else}
    <CenteredParagraph
      content="{notSeeingItText[$workflowLang]}"
      className="mb-2"
    />
  {/if}
  <Button
    type="button"
    disabled="{resent}"
    on:click="{resend}"
    color="primary"
    label="{resent ? sentText[$workflowLang] : resendText[$workflowLang]}"
  />
</TwoLayout>

<style>
button {
  @apply flex flex-row items-center gap-2;
  @apply border rounded-md;
  @apply px-10 py-3;
  @apply text-white;
}
button.filled {
  background-color: var(--primary);
  @apply text-white;
  @apply border-transparent;
}
button.outlined {
  @apply bg-white;
  color: var(--primary);
  border-color: var(--primary);
}

button:hover {
  opacity: 0.95;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: wait;
}
</style>

<script>
export let type = "submit"
export let filled = true
$: outlined = !filled
</script>

<button type="{type}" class:filled class:outlined on:click>
  <slot />
</button>

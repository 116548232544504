import ky from "ky"
import { getAccessToken } from "@local/store/auth"

const prefixUrl = "SVELTE_APP_API_URL"

export default ky.create({
  prefixUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set("Authorization", `bearer ${await getAccessToken()}`)
      },
    ],
  },
})

export const anonymousApi = ky.create({ prefixUrl })

const defaultErrMsgs = {
  shouldBeValid: "Should be valid",
}

export async function handleErrors({ err, customErrMsgs }) {
  if (err.response.status === 422) {
    const errCodes = await err.response.json()
    let errors = {}
    for (let field in errCodes) {
      const customField = customErrMsgs[field]
      for (let err of errCodes[field]) {
        let errMsg = defaultErrMsgs[err] || err
        if (customField && customField[err]) errMsg = customField[err]
        errors[field] = [...(errors[field] || []), errMsg]
      }
    }
    return errors
  }
}

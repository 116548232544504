<style>
.wrapper {
  @apply flex flex-1 flex-col;
  @apply w-full max-w-screen-xl;
  @apply mx-auto;
}

.column {
  @apply flex flex-1 flex-col;
  @apply w-full;
  min-height: calc(100vh - var(--top-bar-offset));
}

.column.visuals {
  @apply px-4 py-16;
  @apply bg-white;
  @apply border border-t-0 border-gray-400;
}

@screen sm {
  .column.visuals {
    @apply px-24;
  }
}

@screen md {
  .column.visuals {
    @apply px-49;
  }
}
</style>

<script>
export let layoutOnly = false
$: visuals = !layoutOnly
</script>

<div class="wrapper">
  <div class="column" class:visuals>
    <slot />
  </div>
</div>

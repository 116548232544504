<style>
.item {
  @apply px-4 py-2;
}
.item.current {
  @apply bg-gray-300 rounded-md text-gray-800;
}
.main :global(h1) {
  @apply my-4 text-3xl font-bold;
}
.main :global(h2) {
  @apply my-4 text-xl font-semibold;
}
.main :global(p) {
  @apply mb-4;
}
.main :global(a) {
  @apply underline text-blue-400;
}
.main :global(ol) {
  @apply list-decimal pl-6;
}
.main :global(ul) {
  @apply list-disc pl-6;
}
.main :global(li:not(:last-child)) {
  @apply mb-2;
}
</style>

<script>
import { link } from "svelte-routing"
export let current = "terms"
</script>

<div class="flex w-full max-w-screen-xl mx-auto py-8">
  <div class="flex flex-col text-gray-600 gap-2 flex-0 w-72 mr-8">
    <a
      use:link
      href="/terms/terms-of-service"
      class="item"
      class:current="{current === 'terms'}"
    >Terms of Service</a>
    <a
      use:link
      href="/terms/privacy-policy"
      class="item"
      class:current="{current === 'privacy'}"
    >Privacy Policy</a>
    <a
      use:link
      href="/terms/medical-terms-of-service"
      class="item"
      class:current="{current === 'medical'}"
    >Medical Terms</a>
  </div>
  <div class="main bg-white rounded-md flex-1 py-2 px-4">
    <slot />
  </div>
</div>

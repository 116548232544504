<style>
.column {
  @apply flex flex-col w-full h-full;
  min-height: calc(100vh - var(--top-bar-offset));
}
</style>

<script>
import OneTwoLayout from "@shared/components/OneTwoLayout.svelte"
import Render from "@local/components/Render.svelte"

export let elementsLeft = undefined
export let elementsRight = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
</script>

<OneTwoLayout>
  <div class="column" slot="left">
    {#each elementsLeft as element}
      <Render
        actions="{actions}"
        element="{element}"
        allErrors="{allErrors}"
        bind:payload
      />
    {/each}
  </div>
  <div class="column" slot="right">
    {#each elementsRight as element}
      <Render
        actions="{actions}"
        element="{element}"
        allErrors="{allErrors}"
        bind:payload
      />
    {/each}
  </div>
</OneTwoLayout>

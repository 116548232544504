<style>
.wrapper {
  @apply flex;
  @apply pt-4;
  min-height: calc(100vh - var(--top-bar-offset));
}
</style>

<script>
import Render from "@local/components/Render.svelte"
import { navigate } from "svelte-routing"
import API, { handleErrors } from "@local/utils/api"
import { workflowLang } from "@shared/store/workflowKey"
import { currentUser, auth } from "@local/store/auth"
import { getView, patchView, patchLanguage } from "@local/config/datasource"

export let workflowId = undefined
export let viewKey
let elements = []
let payload = {}
let errors = {}

$: {
  console.info(`Switching language to ${$workflowLang}`)
  fetchView({ workflowId, viewKey })
  updateUserLanguage($workflowLang)
}

async function updateUserLanguage(language) {
  const partnerId = $currentUser.partnerId
  const userId = $currentUser.id
  const userLanguage = $currentUser.language

  if (userLanguage !== language) {
    try {
      const tokens = await patchLanguage({ partnerId, userId, language })
      auth(tokens)
    } catch (err) {
      console.error("Unable to save language preferences", err)
    }
  }
}

async function fetchView({ workflowId, viewKey }) {
  const response = await getView({ workflowId, viewKey, role: "doctor" })
  elements = response.elements
  payload = response.payload
}

async function submit() {
  const buttonNode = document.querySelector("button[type='submit']")
  buttonNode && (buttonNode.disabled = true)

  try {
    await patchView({ workflowId, viewKey, payload, role: "doctor" })
    navigate(`/doctor-workstation/workflows/${workflowId}`)
  } catch (err) {
    errors = await handleErrors({ err, customErrMsgs: {} })
    console.log(errors)
  } finally {
    buttonNode && (buttonNode.disabled = false)
  }
}
</script>

<form
  on:submit|preventDefault
  class="w-full"
  autocomplete="off"
  on:submit="{submit}"
>
  {#each elements as element}
    <Render element="{element}" allErrors="{errors}" bind:payload="{payload}" />
  {/each}
</form>

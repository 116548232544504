<style>
div {
  @apply border-2 rounded-lg px-4 py-8;
}
div.primary {
  background-color: var(--primary-lighter);
  color: var(--primary);
}
div.red {
  @apply bg-red-200 border-red-400;
  color: "black";
}
div.blue {
  @apply border-blue-400 bg-blue-100;
}
div.yellow {
  @apply border-yellow-400 bg-yellow-300;
}

h3 {
  @apply text-base font-medium text-center uppercase;
}
p {
  @apply text-base font-medium mt-6;
}
</style>

<script>
export let title = ""
export let content = ""
export let className = ""
export let color = "primary"

$: primary = color === "primary"
$: red = color === "red"
$: blue = color === "blue"
$: yellow = color === "yellow"
</script>

<div
  class="{className}"
  class:primary="{primary}"
  class:red="{red}"
  class:blue="{blue}"
  class:yellow="{yellow}"
>
  {#if title}
    <h3>
      {title}
    </h3>
  {/if}
  {#if content}
    <p>{content}</p>
  {/if}
</div>

<style>
.button {
  @apply border-2 p-4 font-medium rounded-md outline-none;
}
.button-sm {
  @apply w-12 border-2 p-2 font-semibold text-lg outline-none;
}
.button:hover {
  @apply opacity-75;
}
.button.empty {
  border-color: white;
  background-color: white;
}
.button.empty.transparent,
.button.empty.primary {
  color: var(--primary);
}
.button.empty.black {
  @apply text-black;
}
.button.filled {
  @apply text-white;
}
.button.filled.black {
  @apply bg-black border-black;
}
.button.filled.white {
  @apply bg-white border-white text-black;
}
.button.filled.primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-accent);
}
.button.filled.transparent {
  background-color: "transparent";
  border-color: "transparent";
  color: var(--primary);
}
.button.outlined.black {
  @apply border-black text-black;
}
.button.outlined.white {
  @apply border-white text-white;
}
.button.outlined.primary {
  color: var(--primary);
  border-color: var(--primary);
}
.button.outlined.transparent {
  color: var(--primary);
  border-color: "transparent";
}
.button.filled.primary:disabled,
.button.filled.black:disabled,
.button.filled.white:disabled,
.button.outline.primary:disabled,
.button.outline.black:disabled,
.button.outline.white:disabled {
  color: #757575;
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}
.button.outline.transparent:disabled {
  color: #757575;
  background-color: "transparent";
  border-color: "transparent";
}
.label {
  @apply flex flex-1 items-center justify-center;
}
.font-size-sm {
  @apply text-sm;
}
.font-size-normal {
  @apply text-base;
}
.font-size-lg {
  @apply text-lg;
}
.font-size-xl {
  @apply text-xl;
}
.font-size-2xl {
  @apply text-2xl;
}
.font-size-3xl {
  @apply text-3xl;
}
.label.rightIcon {
  @apply -mr-6;
}
.icon {
  @apply flex justify-center items-center;
  font-size: 24px;
}
.icon.rightIcon {
  @apply flex-grow-0;
}
.icon.leftIcon {
  @apply mr-2;
}
.icon.medium {
  font-size: 24px;
}
.icon.large {
  font-size: 36px;
}
.icon.extraLarge {
  font-size: 48px;
}
.hint {
  @apply text-center px-4 mt-4;
  font-size: 15px;
  letter-spacing: -0.36px;
  line-height: 1.4;
}
.button.wFull {
  @apply w-full;
}
.button.wHalf {
  @apply w-1/2;
}
.button.wThird {
  @apply w-1/3;
}
.button.wQuarter {
  @apply w-1/4;
}
.button.wOneFifth {
  @apply w-1/5;
}
.positionCenter {
  @apply flex justify-center;
}
.positionLeft {
  @apply flex justify-start;
}
.positionRight {
  @apply flex justify-end;
}
.border-none {
  @apply border-none;
}
.border-1 {
  @apply border-1;
}
.border-2 {
  @apply border-2;
}
</style>

<script>
export let type = undefined
export let label = ""
export let filled = true
export let outlined = false
export let color = "black"
export let leftIcon = undefined
export let rightIcon = undefined
export let hint = undefined
export let disabled = false
export let className = ""
export let iconSize = "medium"
export let classNameLabel = ""
export let width = "full"
export let position = "center"

$: empty = !filled && !outlined

$: black = color === "black"
$: primary = color === "primary"
$: white = color === "white"
$: transparent = color === "transparent"
$: wFull = width === "full"
$: wHalf = width === "half"
$: wThird = width === "third"
$: wQuarter = width === "quarter"
$: wOneFifth = width === "oneFifth"
$: positionCenter = position === "center"
$: positionLeft = position === "left"
$: positionRight = position === "right"
</script>

<div
  class:positionCenter="{positionCenter}"
  class:positionLeft="{positionLeft}"
  class:positionRight="{positionRight}"
>
  <button
    type="{type}"
    on:click
    disabled="{disabled}"
    class="button border-2 {className}"
    class:filled="{filled}"
    class:outlined="{outlined}"
    class:black="{black}"
    class:primary="{primary}"
    class:transparent="{transparent}"
    class:white="{white}"
    class:leftIcon="{leftIcon}"
    class:rightIcon="{rightIcon}"
    class:empty="{empty}"
    class:wFull="{wFull}"
    class:wHalf="{wHalf}"
    class:wThird="{wThird}"
    class:wQuarter="{wQuarter}"
    class:wOneFifth="{wOneFifth}"
  >
    <span class="label {classNameLabel}" class:rightIcon="{rightIcon}">
      {#if leftIcon}
        <i class="icon leftIcon material-icons {iconSize}">{leftIcon}</i>
      {/if}
      {label}
    </span>
    {#if rightIcon}
      <i class="icon rightIcon material-icons {iconSize}">{rightIcon}</i>
    {/if}
  </button>
  {#if hint}
    <div class="hint">{hint}</div>
  {/if}
</div>

<style>
.content {
  @apply flex flex-1 flex-col;
  @apply w-full;
  min-height: calc(100vh - var(--top-bar-offset));
}
</style>

<div class="content">
  <slot />
</div>

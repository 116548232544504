<style>
.wrapper {
  @apply px-6 py-4;
}

.item-url {
  color: var(--primary);
}
</style>

<script>
export let items = []
</script>

<div class="wrapper">
  {#each items as item, i}
    {#if item.url}
      <a class="item-url" href="{item.url}">
        {item.label}
        {#if i !== items.length - 1}/{/if}
      </a>
    {:else}
      <span>
        {item.label}
        {#if i !== items.length - 1}/{/if}</span>
    {/if}
  {/each}
</div>

<style>
h2 {
  @apply leading-tight tracking-normal text-2xl font-semibold text-center;
}
h2 > :global(a) {
  color: var(--primary);
}
h2 > :global(a:hover) {
  @apply underline;
}
.font-size-sm {
  @apply text-sm;
}
.font-size-normal {
  @apply text-base;
}
.font-size-lg {
  @apply text-lg;
}
.font-size-xl {
  @apply text-xl;
}
.font-size-2xl {
  @apply text-2xl;
}
.font-size-3xl {
  @apply text-3xl;
}
.font-weight-normal {
  @apply font-normal;
}
.font-weight-semibold {
  @apply font-semibold;
}
.font-weight-bold {
  @apply font-bold;
}
.text-align-center {
  @apply text-center;
}
.text-align-left {
  @apply text-left;
}
.text-align-right {
  @apply text-right;
}
</style>

<script>
export let content = ""
export let className = ""
</script>

<h2 class="{className}">
  {@html content}
</h2>

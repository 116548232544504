<script>
import Button from "./Button.svelte"

export let url = undefined
</script>

<Button
  {...$$restProps}
  on:click="{() => {
    window.open(url, '_blank')
  }}"
  type="button"
/>

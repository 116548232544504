<style>
.wrapper {
  @apply flex flex-1 flex-row;
  @apply w-full max-w-screen-xl;
  @apply mx-auto;
}

.left-column {
  @apply flex flex-col;
  @apply w-1/3 max-h-screen;
  @apply sticky top-0;
  @apply border border-t-0 border-b-0 border-gray-400;
  min-height: calc(100vh - var(--top-bar-offset));
}

.right-column {
  @apply flex flex-col;
  @apply w-2/3;
  @apply border border-t-0 border-l-0 border-gray-400;
  min-height: calc(100vh - var(--top-bar-offset));
}
</style>

<div class="wrapper">
  <div class="left-column">
    <slot name="left" />
  </div>
  <div class="right-column">
    <slot name="right" />
  </div>
</div>

<script>
import { Tabs, TabList, TabPanel, Tab } from "./_Tabs"
import Render from "./Render.svelte"

export let tabs = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
export let className = ""
</script>

<Tabs>
  <TabList className="tab-list px-8 border-b border-gray-400 {className}">
    {#each tabs as tab}
      <Tab>{tab.label}</Tab>
    {/each}
  </TabList>
  {#each tabs as tab}
    <TabPanel className="flex-1 border-l border-r border-gray-400 bg-white p-8">
      {#each tab.elements as element}
        <Render
          actions="{actions}"
          element="{element}"
          allErrors="{allErrors}"
          bind:payload
        />
      {/each}
    </TabPanel>
  {/each}
</Tabs>

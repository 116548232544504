<style>
.add {
  @apply px-4 flex items-center font-medium mt-2 cursor-pointer;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
}
.add:hover {
  color: #479df7;
}
.add-icon {
  @apply mr-1;
}
.delete {
  @apply px-4 flex items-center mt-2 cursor-pointer;
  color: #c4272a;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
.calculate {
  @apply px-4 flex items-center font-medium mt-2 cursor-pointer;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
}
h2 {
  @apply leading-tight tracking-normal text-2xl font-semibold text-center;
}
</style>

<script>
import Autocomplete from "./Autocomplete.svelte"
import NumberInput from "./NumberInput.svelte"
import Select from "./_Select.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  addMoreText,
  calculateMorphineText,
  totalDailyMorphineEquivalencyText,
} from "@shared/utils/translations"
import API from "@local/utils/api"

export let name = undefined
export let label = undefined
export let value = undefined
export let workflowId
export let options = []
export let className = ""
export let frequencyOptions
export { _errors as errors }

$: errors = _errors || []
$: hasErrors = errors.length > 0
$: morphineQuantity = undefined

let _errors
let values = value || [{}]

function addValue() {
  if (validate()) {
    values.push({})
    values = values
  }
}

function validate() {
  for (let value of values) {
    if (!value.medicine || !value.quantity || !value.frequency) {
      errors.push("cannot be blank")
      errors = errors
      return false
    }
  }
  errors = []
  return true
}

function removeValue(i) {
  values.splice(i, 1)
  values = values
  errors = []
}

function onChange() {
  value = values.filter((x) => !!x)
}

async function calculateMorphine() {
  if (!validate()) {
    morphineQuantity = undefined
  } else {
    try {
      const searchParams = {
        role: "doctor",
      }
      let response = await API.post(`medicines/${workflowId}/morphine`, {
        searchParams,
        json: values,
      }).json()

      morphineQuantity = response.value
    } catch (err) {
      console.log(err)
    }
  }
}

$: values, onChange()
</script>

<div class="w-full {className}">
  {#each values as value, i}
    <div
      class:mb-6="{i < values.length - 1}"
      class:mb-4="{i === values.length - 1 && !hasErrors}"
      class="flex flex-row gap-2"
    >
      <Autocomplete
        name="{`${name}-medicine-${i}`}"
        label="{label}"
        options="{options}"
        hasErrors="{!errors}"
        bind:value="{value.medicine}"
      />

      <NumberInput
        name="{`${name}-quantity-${i}`}"
        label="Quantity ml / mg"
        hasErrors="{!errors}"
        bind:value="{value.quantity}"
      />

      <Select
        name="{`${name}-frequency-${i}`}"
        label="Frequency / day"
        options="{frequencyOptions}"
        bind:value="{value.frequency}"
        hasErrors="{hasErrors}"
        includeEmpty="{true}"
      />

      {#if values.length > 1}
        <div class="delete">
          <i
            class="material-icons"
            on:click="{() => removeValue(i)}"
          >remove_circle</i>
        </div>
      {/if}
    </div>
    {#if i === values.length - 1}
      {#if hasErrors}
        <div class="error">{errors[0]}</div>
      {/if}
    {/if}
  {/each}

  <div class="flex flex-row gap-2">
    <div class="add" on:click="{addValue}">
      <i class="add-icon material-icons">add_circle</i>
      {addMoreText[$workflowLang]}
    </div>

    <div class="add" on:click="{calculateMorphine}">
      <i class="add-icon material-icons">calculate</i>
      {calculateMorphineText[$workflowLang]}
    </div>
  </div>

  {#if morphineQuantity !== undefined}
    <div class="mt-8">
      <h2>
        {totalDailyMorphineEquivalencyText[$workflowLang]}:
        {morphineQuantity}
      </h2>
    </div>
  {/if}
</div>

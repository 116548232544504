<style>
.wrapper {
  @apply w-full;
}
.inputs-wrapper {
  @apply grid grid-cols-3 gap-4;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import getDate from "date-fns/getDate"
import getMonth from "date-fns/getMonth"
import getYear from "date-fns/getYear"
import parseISO from "date-fns/parseISO"
import subYears from "date-fns/subYears"
import compareAsc from "date-fns/compareAsc"
import { workflowLang } from "@shared/store/workflowKey"
import { youNeedToBeAtLeastXYearsOldText } from "@shared/utils/translations"

import Select from "./_Select.svelte"
import SubHeading from "./SubHeading.svelte"

export let name = ""
export let label = ""
export let value = undefined
export let className = ""
export let ageGate = ""
export let useUTC = ""
export let startYear = getYear(new Date())
export let sortYear = "desc"
export let qtyYear = 110

let year = value ? `${getYear(new Date(value))}` : ""
let month = value ? `${getMonth(new Date(value)) + 1}` : ""
let day = value ? `${getDate(new Date(value))}` : ""

const monthOptions = buildOptions(1, 12, "asc")
const yearOptions = buildOptions(startYear, qtyYear, sortYear)
const dayOptions = buildOptions(1, 31, "asc")

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

$: updateValue(year, month, day)

function updateValue(year, month, day) {
  if (year && month && day) {
    const dd = `${day}`.padStart(2, "0")
    const mm = `${month}`.padStart(2, "0")
    const selectedDate = `${year}-${mm}-${dd}`
    value = parseISO(selectedDate)
    if (useUTC) {
      // 12:59:59 will work for -10 Timezones as well as +11 Timezones without landing on a different day
      value = parseISO(`${selectedDate}T12:59:59.000-0000Z`)
    }

    if (!!ageGate && ageGate !== 0) {
      const limit = subYears(new Date(), ageGate)

      if (compareAsc(limit, value) < 0) {
        errors = [youNeedToBeAtLeastXYearsOldText[$workflowLang](ageGate)]
        hasErrors = true
      } else {
        errors = []
        hasErrors = false
        if (useUTC) {
          // 12:59:59 will work for -10 Timezones as well as +11 Timezones without landing on a different day
          value = parseISO(`${selectedDate}T12:59:59.000-0000Z`)
        }
      }
    }
  } else {
    value = undefined
  }
}

function buildOptions(startOption, qtyOption, sort) {
  return new Array(qtyOption)
    .fill(0)
    .map((_, i) => (sort === "asc" ? startOption + i : startOption - i))
    .map((x) => `${x}`)
}
</script>

<div class="wrapper {className}" id="{name}">
  <SubHeading className="mb-8" content="{label}" />
  <div class="inputs-wrapper">
    <Select
      label="{'mm'}"
      options="{monthOptions}"
      bind:value="{month}"
      hasErrors="{hasErrors}"
      includeEmpty="{true}"
    />
    <Select
      label="{'dd'}"
      options="{dayOptions}"
      bind:value="{day}"
      hasErrors="{hasErrors}"
      includeEmpty="{true}"
    />
    <Select
      label="{'yyyy'}"
      options="{yearOptions}"
      bind:value="{year}"
      hasErrors="{hasErrors}"
      includeEmpty="{true}"
    />
  </div>
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
</div>

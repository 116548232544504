<style>
.box {
  @apply w-full flex flex-col p-6 rounded-2xl;
  box-shadow: 0 0 3.5rem 0 rgba(0, 0, 0, 0.1);
}
</style>

<script>
import Render from "./Render.svelte"
export let actions = undefined
export let elements = undefined
export let allErrors = undefined
export let payload = undefined
export let className = ""
</script>

<div class="box {className}">
  {#each elements as element}
    <Render
      element="{element}"
      allErrors="{allErrors}"
      actions="{actions}"
      bind:payload
    />
  {/each}
</div>

<style>
.font-size-sm {
  @apply text-sm;
}
.font-size-normal {
  @apply text-base;
}
.font-size-lg {
  @apply text-lg;
}
.font-size-xl {
  @apply text-xl;
}
.font-size-2xl {
  @apply text-2xl;
}
.font-size-3xl {
  @apply text-3xl;
}
.font-weight-normal {
  @apply font-normal;
}
.font-weight-semibold {
  @apply font-semibold;
}
.font-weight-bold {
  @apply font-bold;
}
.text-align-center {
  @apply text-center;
}
.text-align-left {
  @apply text-left;
}
.text-align-right {
  @apply text-right;
}
</style>

<script>
export let content = ""
export let className = ""
</script>

<p class="mb-4 whitespace-pre-wrap type-paragraph {className}">{content}</p>

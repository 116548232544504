<script>
import { referral } from "@local/store/referral"
import { partner } from "@local/store/theme"

export let content = ""

let templatedContent = content

const transformers = {}

function lookup(propKey) {
  if (propKey === "price") {
    let discount = 0
    if ($referral) {
      if (typeof $referral.props?.discount === "object") {
        discount = $referral.props?.discount?.new
      } else {
        discount = $referral.props?.discount
      }
    }
    return $partner.origin.props.price - discount
  }
  return propKey
}

function updateContent(value) {
  let newContent = value
  const matches = value.match(/\{\{(.*?)\}\}/gm)
  if (!matches) return (templatedContent = value)

  for (let match of matches) {
    const fullKey = match
    const key = fullKey.replace(/(\{|\})/g, "")
    let [propKey, ...transforms] = key.split("||")
    let data = lookup(propKey)
    if (data) {
      for (let transform of transforms) {
        data = transformers[transform](data)
      }
    }
    newContent = newContent.replace(fullKey, data)
  }
  templatedContent = newContent
}

$: updateContent(content)
</script>

<span>{@html templatedContent}</span>

<script>
import { Router, Route } from "svelte-routing"

import Queue from "./queue/index.svelte"
import WorkflowDetail from "./workflows/[workflowId].svelte"
import ViewKey from "./workflows/views/[viewKey].svelte"
</script>

<Router>
  <Route path="workflows/:workflowId" component="{WorkflowDetail}" />
  <Route path="workflows/:workflowId/views/:viewKey" component="{ViewKey}" />
  <Route path="/">
    <Queue />
  </Route>
</Router>

import { writable } from "svelte/store"

const subdomain = window.location.hostname.split(".")[0]
let key = `hellomdReferralUtms+${subdomain}`

export const referralUtms = writable()

try {
  const initialState = JSON.parse(localStorage.getItem(key))
  if (initialState) referralUtms.set(initialState)
} catch (err) {
  console.error(err)
}

export function setReferralUtms(_referralUtms) {
  if (!_referralUtms) {
    localStorage.setItem(key, undefined)
    referralUtms.set(undefined)
    return
  }

  localStorage.setItem(key, JSON.stringify(_referralUtms))
  referralUtms.set(_referralUtms)
}

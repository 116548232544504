<script>
import Layout from "./Layout.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  myMediInfoText,
  myMediInfoDescriptionText,
} from "@shared/utils/translations"

export let subdomain = ""
</script>

<Layout>
  {#if subdomain === "mymedi" || subdomain === "seniorcareroc"}
    <h2>{myMediInfoText[$workflowLang]}</h2>
    <p>{myMediInfoDescriptionText[$workflowLang]}</p>
  {/if}
  {#if subdomain === "tantus"}
    <h2>Tantus Info</h2>
    <p>
      Patients will be sent to this page post consult to register:
      <a href="https://tantushealthco.com/medical-registration-next-steps/"
        >https://tantushealthco.com/medical-registration-next-steps/</a
      >
      <br />
      Mon--Fri they will be approved within 24 hrs of receiving their medical documents.
      Then they will received a “Welcome” email with a link to activate their account
      and complete their profile. They are then ready to start ordering.
    </p>
  {/if}
  {#if subdomain === "starseed"}
    <h2>Starseed Info</h2>
    <p>
      Please remind patients to return to Starseed to register - if they have
      not already. Once they register, they will receive an email from Starseed
      support in 24-48 hours so that they are approved and can order. For
      product selection help they can call 844-633-2627<br />They will receive
      an email from us right after the medical document is posted with this
      information.
    </p>
  {/if}
  {#if subdomain === "cannafarms"}
    <h2>Canna Farms Medical Info</h2>
    <p>
      Please remind patients to return to Canna Farms Medical to register - if
      they have not already. Once they register, they will receive an email from
      Canna Farms Medical support in 24-48 hours so that they are approved and
      can order. For product selection help they can call 1-855-882-0988
      <br />They will receive an email from us right after the medical document
      is posted with this information.
    </p>
  {/if}
</Layout>

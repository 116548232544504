<style>
.external-link {
  color: var(--primary);
}
</style>

<script>
import { tick, onMount } from "svelte"
import queryString from "query-string"
import { navigate, link } from "svelte-routing"

import API, { anonymousApi, handleErrors } from "@local/utils/api"

import {
  partnerId as _partnerId,
  partner as _partner,
  externalLogin,
  registrationPageTitle,
  registrationPageSubTitle,
  registrationDisabled,
  registrationInfoPath,
  registrationPageShowInfoBox,
  registrationPageShowBanner,
  registrationPageShowTrustedPaymentBanner,
  trustedPaymentPath,
  infoBoxHeader,
  infoBoxP1,
  infoBoxP2,
  infoBoxP3,
} from "@local/store/theme"

import { auth } from "@local/store/auth"
import { workflowLang } from "@shared/store/workflowKey"

import Link from "@local/components/Link.svelte"
import TextInput from "@local/components/TextInput.svelte"
import TwoLayout from "@shared/components/TwoLayout.svelte"
import Heading from "@local/components/Heading.svelte"
import Submit from "@local/components/Submit.svelte"
import {
  emailText,
  passwordText,
  renewingOrAlreadyRegisteredText,
  nextText,
  createYourPasswordWithAtLeastXCharacteres,
  andText,
  byRegisteringYouAgreeToTheText,
  termsOfServiceText,
  privacyPolicyText,
} from "@shared/utils/translations"
import { setPartner } from "@local/store/theme"
import { referral as _referral } from "@local/store/referral"
import { referralUtms as _referralUtms } from "@local/store/referralUtms"

export let location = {}

let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next
$: lang = queryParams.lang || $workflowLang

$: partnerId = $_partnerId

$: setPartner($_partner, $_referral)

onMount(async () => {
  if ($registrationDisabled || $externalLogin) {
    navigate("/")
  }
})

let form = {
  email: "",
  password: "",
}
let errors = {}

let subdomain = window.location.hostname.split(".").shift() || ""
let tosUrl = ""
let privacyPolicyUrl = ""
let loading = false

const hideAlreadyRegistered = ["aurora", "cccc", "ccct"].includes(subdomain)

const customErrMsgs = {
  email: {
    alreadyExists: `You already have an account with us.${
      hideAlreadyRegistered
        ? ""
        : " Please click below on ‘Already registered’ to continue."
    } `,
  },
}

if ($_partner.name === "HelloMD") {
  tosUrl = "https://hellomd.com/terms-of-use"
  privacyPolicyUrl = "https://hellomd.com/privacy-policy"
} else {
  tosUrl = "https://hellomd.ca/terms-of-use"
  privacyPolicyUrl = "https://hellomd.ca/privacy-policy"
}

function trackConversion() {
  window.dataLayer = window.dataLayer || []

  function gtag() {
    dataLayer.push(arguments)
  }

  gtag("event", "conversion", {
    send_to: "AW-732075948/YRY5CJfKn8ECEKyvit0C",
  })
}

// Activity ID: 13471853
function endConversion() {
  if (subdomain === "illinois5") {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag("js", new Date())
    gtag("config", "DC-12569754")
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-12569754/conv30/hello00+unique",
    })
  }
}

// Activity ID: 13619756
function startConversion() {
  if (subdomain === "illinois5") {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag("js", new Date())
    gtag("config", "DC-12569754")
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-12569754/conv30/step10+unique",
    })
  }
}

async function submit() {
  if (loading) return
  loading = true

  try {
    let referralUtmSource = queryParams?.utm_source
    if (!referralUtmSource) {
      referralUtmSource = $_referralUtms?.referralUtmSource
    }
    let referralUtmCampaign = queryParams?.utm_campaign
    if (!referralUtmCampaign) {
      referralUtmCampaign = $_referralUtms?.referralUtmCampaign
    }

    const query = new URLSearchParams()
    if (next) query.set("next", next)
    if (referralUtmSource) query.set("referralUtmSource", referralUtmSource)
    if (referralUtmCampaign)
      query.set("referralUtmCampaign", referralUtmCampaign)

    const path = `partners/${partnerId}/users${
      query.toString() ? `?${query.toString()}` : ""
    }`
    const tokens = await anonymousApi
      .post(path, {
        json: {
          ...form,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          partnerOrigin: subdomain,
          lang,
          referralCode: $_referral?.code || null,
        },
      })
      .json()
    auth(tokens)
    trackConversion()
    endConversion()

    API.post("api/campaigner", {
      json: { email: form.email, funnel: subdomain },
    })
      .then()
      .catch((err) =>
        console.log(
          `Failure to send ${form.email} to Campaigner subscribers list. ${err}`
        )
      )

    await tick()

    const queryNext = new URLSearchParams()
    if (referralUtmSource) queryNext.set("utm_source", referralUtmSource)
    if (referralUtmCampaign) queryNext.set("utm_campaign", referralUtmCampaign)

    const nextPath = `${next || "/"}${
      queryNext.toString() ? `?${queryNext.toString()}` : ""
    }`

    navigate(nextPath, { replace: true })
  } catch (err) {
    errors = await handleErrors({ err, customErrMsgs })
  } finally {
    loading = false
  }
}

onMount(() => {
  startConversion()
})
</script>

<TwoLayout>
  <form on:submit|preventDefault autocomplete="off" on:submit="{submit}">
    <Heading content="{$registrationPageTitle}" className="mb-6" />
    {#if $registrationPageSubTitle}
      <Heading
        content="{$registrationPageSubTitle}"
        className="mb-8 font-size-2xl"
      />
    {/if}
    {#if $registrationPageShowBanner}
      <img src="{$registrationInfoPath}" alt="Info" class="mb-8" />
    {/if}

    <TextInput
      name="email"
      label="{emailText[lang]}*"
      bind:value="{form.email}"
      errors="{errors.email}"
      className="mb-6"
    />
    <TextInput
      name="password"
      label="{passwordText[lang]}*"
      bind:value="{form.password}"
      type="password"
      errors="{errors.password}"
      hint="{createYourPasswordWithAtLeastXCharacteres[lang](8)}"
      className="mb-12"
    />
    <Submit
      label="{nextText[lang]}"
      color="primary"
      className="mb-6"
      classNameLabel="font-size-xl"
    />
    {#if !hideAlreadyRegistered}
      <div class="text-center">
        <Link href="/login{location.search}" class="font-size-normal">
          {renewingOrAlreadyRegisteredText[lang]}
        </Link>
      </div>
    {/if}
    <div class="text-sm text-center mt-8">
      {byRegisteringYouAgreeToTheText[lang]}<a
        use:link
        href="{tosUrl}"
        class="external-link">{termsOfServiceText[lang]}</a
      >
      {andText[lang]}
      <a use:link href="{privacyPolicyUrl}" class="external-link"
        >{privacyPolicyText[lang]}</a
      >
    </div>

    {#if $registrationPageShowInfoBox}
      <div class="mt-8 bg-blue-200 p-4 pt-6 pb-6 mb-10">
        <h4 class="text-center font-semibold">{$infoBoxHeader}</h4>
        {#if $infoBoxP1}
          <p class="mt-4">{$infoBoxP1}</p>
        {/if}
        {#if $infoBoxP2}
          <p class="mt-4">{$infoBoxP2}</p>
        {/if}
        {#if $infoBoxP3}
          <p class="mt-4">{$infoBoxP3}</p>
        {/if}
      </div>
    {/if}
    {#if $registrationPageShowTrustedPaymentBanner}
      <div class="mt-6">
        <img src="{$trustedPaymentPath}" alt="Trusted Payment" />
      </div>
    {/if}
  </form>
</TwoLayout>

<!--
  1st img
  Event snippet for HelloMD - Conversion - Illinois Step1 on https://illinois5.hellomd.com/register?next=%2Fget-started: Please do not remove.
  Place this snippet on pages with events you're tracking.
  Creation date: 11/03/2022

  2nd img
  Event snippet for Step 1 - Conversion - Application Start on https://illinois5.hellomd.com/verify: Please do not remove.
  Place this snippet on pages with events you’re tracking.
  Creation date: 12/15/2022
-->
{#if subdomain === "illinois5"}
  <noscript>
    <img
      src="https://ad.doubleclick.net/ddm/activity/src=12569754;type=conv30;cat=hello00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?"
      width="1"
      height="1"
      alt=""
    />

    <img
      src="https://ad.doubleclick.net/ddm/activity/src=12569754;type=conv30;cat=step10;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=1?"
      width="1"
      height="1"
      alt=""
    />
  </noscript>
{/if}
<!-- End of event snippet: Please do not remove -->

import { writable } from "svelte/store"
import jwtDecode from "jwt-decode"
import { isPast } from "date-fns"
import { navigate } from "svelte-routing"

import { postToken } from "@local/config/datasource"

import { partnerId as _partnerId } from "@local/store/theme"
import {
  workflowLang as _workflowLang,
  setWorkflowLang,
} from "@shared/store/workflowKey"

const VERSION_TOKEN = 3

const subdomain = window.location.hostname.split(".")[0]

let partnerId = null
let key = `hellomdToken+${subdomain}.v${VERSION_TOKEN}`
_partnerId.subscribe(partnerIdChanged)

let workflowLang
_workflowLang.subscribe(function (value) {
  workflowLang = value
})

function partnerIdChanged(newPartnerId) {
  partnerId = newPartnerId
}

const isLoggedIn = writable(false)
const isEmailConfirmed = writable(false)
const currentUser = writable(null)

let user = null
let accessToken = null
let refreshToken = null
let refreshRequest = null

try {
  const initialState = JSON.parse(localStorage.getItem(key))
  if (initialState) auth(initialState)
} catch (err) {
  console.log(err)
}

function getUserFromJWT() {
  const decodedJWT = jwtDecode(accessToken)
  return {
    id: decodedJWT.id,
    email: decodedJWT.email,
    roles: decodedJWT.roles,
    partnerId: decodedJWT.partnerId,
    partnerOrigin: decodedJWT.partnerOrigin,
    isEmailConfirmed: decodedJWT.isEmailConfirmed,
    language: decodedJWT.language,
    parentId: decodedJWT.parentId,
    hasSubUsers: decodedJWT.hasSubUsers,
    rootId: decodedJWT.rootId,
  }
}

export function auth(tokens) {
  accessToken = tokens.accessToken
  refreshToken = tokens.refreshToken
  user = getUserFromJWT(tokens.accessToken)

  if (user.partnerOrigin === subdomain) {
    isEmailConfirmed.set(user.isEmailConfirmed)
    currentUser.set(user)
    isLoggedIn.set(true)

    localStorage.setItem(
      `hellomdToken+${user.partnerOrigin}.v${VERSION_TOKEN}`,
      JSON.stringify(tokens)
    )

    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: "SVELTE_APP_INTERCOM_ID",
        user_id: user.id,
        email: user.email,
        created_at: user.createdAt,
      })
    }
  }

  if (user?.language && user.language !== workflowLang) {
    setWorkflowLang(user.language)
  }

  return user
}

export function deauth() {
  navigate("/start")
  accessToken = null
  refreshToken = null
  user = null
  isLoggedIn.set(false)
  currentUser.set(null)
  localStorage.clear()
  window.Intercom("shutdown")
  window.Intercom("boot", { app_id: "SVELTE_APP_INTERCOM_ID" })
}

export async function refreshAccessToken() {
  try {
    if (refreshRequest) {
      return async function () {
        const [data, { status }] = await refreshRequest
        if (status === 200) return data.accessToken
        deauth()
      }
    }
    refreshRequest = postToken({ email: user.email, refreshToken, partnerId })
    const [data, { status }] = await refreshRequest
    if (status === 200) {
      auth(data)
      refreshRequest = null
      return accessToken
    } else {
      deauth()
      refreshRequest = null
    }
  } catch (err) {
    console.error({ err })
    deauth()
  }
}

export async function getAccessToken() {
  if (!accessToken) return null
  const expiresAt = new Date(jwtDecode(accessToken).exp * 1000)
  if (isPast(expiresAt)) await refreshAccessToken()
  return accessToken
}

export { currentUser, isLoggedIn, isEmailConfirmed }

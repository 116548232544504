<style>
.button {
  @apply h-15 select-none;
}
.input {
  @apply hidden;
}
.label {
  @apply flex flex-row items-center w-full h-full bg-white font-normal cursor-pointer rounded;
  font-size: 1.3125rem;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}
.input:checked + .label {
  @apply font-medium;
  background-color: var(--primary-lighter);
  color: var(--primary);
}
.icon {
  @apply flex-none ml-3;
}
</style>

<script>
export let id = undefined
export let type = undefined
export let label = undefined
export let icon = undefined
export let value = undefined
export let group = undefined
export let checked = undefined
</script>

<div class="button">
  {#if type === 'checkbox'}
    <input
      type="checkbox"
      class="input"
      bind:group
      bind:checked
      id="{id}"
      value="{value}"
    />
  {:else}
    <input type="radio" class="input" bind:group id="{id}" value="{value}" />
  {/if}
  <label for="{id}" class="label">
    {#if icon}<i class="icon material-icons">{icon}</i>{/if}
    <div class="flex-1 text-center">{label}</div>
  </label>
</div>

<script>
import { format, formatDistanceToNow, differenceInYears } from "date-fns"
import { workflowLang } from "@shared/store/workflowKey"
import { fr, enUS as en } from "date-fns/locale"
export let content = ""

let tick
let templatedContent = content
$: localeLang = $workflowLang === "en" ? en : fr

const realTimeTransformers = ["formatTimeSince"]

const provinceMap = {
  Alberta: "(AB)",
  "British Columbia": "(BC)",
  Manitoba: "(MB)",
  "New Brunswick": "(NB)",
  "Newfoundland and Labrador": "(NL)",
  "Northwest Territories": "(NT)",
  "Nova Scotia": "(NS)",
  Nunavut: "(NU)",
  Ontario: "(ON)",
  "Prince Edward Island": "(PE)",
  Quebec: "(QC)",
  Saskatchewan: "(SK)",
  Yukon: "(YT)",
}

const transformers = {
  applyComma: (value) => (value !== "" ? `, ${value}` : ""),
  applyBreakline: (value) => (value !== "" ? `<br/>${value}` : ""),
  formatDateShort: (value) =>
    format(new Date(value), "LLL d, yyyy", { locale: localeLang }),
  formatDate: (value) =>
    format(new Date(value), "LLLL, d, yyyy", { locale: localeLang }),
  formatTime: (value) => format(new Date(value), "p"),
  formatFullTime: (value) => format(new Date(value), "HH:mm:ss"),
  formatDateTime: (value) => format(new Date(value), "P p"),
  formatTimeSince: (value) =>
    localeLang === en
      ? `${formatDistanceToNow(new Date(value), { locale: localeLang })} ago`
      : `Il ya  ${formatDistanceToNow(new Date(value), {
          locale: localeLang,
        })}`,
  formatYearsSince: (value) => differenceInYears(new Date(), new Date(value)),
  getProvinceInitials: (value) => provinceMap[value],
}

function updateContent(value) {
  let shouldRefresh = false
  let newContent = value
  const matches = value.match(/\{\{(.*?)\}\}/gm)
  if (!matches) return (templatedContent = value)

  for (let match of matches) {
    const fullKey = match
    const key = fullKey.replace(/(\{|\})/g, "")
    let [data, ...transforms] = key.split("||")
    if (data) {
      for (let transform of transforms) {
        if (!shouldRefresh && realTimeTransformers.includes(transform)) {
          shouldRefresh = true
        }

        data = transformers[transform](data)
      }
    }
    newContent = newContent.replace(fullKey, data)
  }

  if (tick) {
    clearTimeout(tick)
    tick = null
  }
  tick = setTimeout(() => {
    updateContent(value)
  }, 1000)

  templatedContent = newContent
}

$: updateContent(content)
</script>

<span>{@html templatedContent}</span>

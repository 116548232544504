<style>
.wrapper {
  @apply w-full;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
.hint {
  @apply px-4 mt-1 text-xs leading-none;
}
</style>

<script>
import TextInput from "@shared/components/TextInput.svelte"
export let name = ""
export let label = undefined
export let value = ""
export let className = ""
export let type = "text"
export let hint = ""

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0
</script>

<div class="wrapper {className}">
  <TextInput
    name="{name}"
    label="{label}"
    hasErrors="{hasErrors}"
    type="{type}"
    bind:value
  />
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
  {#if hint && !hasErrors}
    <div class="hint">{hint}</div>
  {/if}
</div>

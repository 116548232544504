<style>
a {
  @apply font-semibold text-lg;
  color: var(--primary);
}
.font-size-sm {
  @apply text-sm;
}
.font-size-normal {
  @apply text-base;
}
.font-size-lg {
  @apply text-lg;
}
.font-size-xl {
  @apply text-xl;
}
.font-size-2xl {
  @apply text-2xl;
}
.font-size-3xl {
  @apply text-3xl;
}
</style>

<script>
import { link } from "svelte-routing"

export let href = ""
export { className as class }

let className = ""
</script>

<a href="{href}" class="{className}" use:link><slot /></a>

<script>
import { onMount } from "svelte"
import queryString from "query-string"
import { navigate } from "svelte-routing"

import { postPasswordResetEmails } from "@local/config/datasource"
import {
  partnerId as _partnerId,
  partner as _partner,
  externalLogin,
  loginDisabled,
} from "@local/store/theme"

import Link from "@local/components/Link.svelte"
import TwoLayout from "@shared/components/TwoLayout.svelte"
import TextInput from "@local/components/TextInput.svelte"
import Heading from "@local/components/Heading.svelte"
import Submit from "@local/components/Submit.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  emailText,
  continueText,
  checkYourEmailText,
  passwordResetText,
  enterYourEmailToReceiveLoginInstructionsText,
} from "@shared/utils/translations"

export let location = {}

let sent = false
let errors = {}
let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next
$: lang = queryParams.lang || $workflowLang

$: partner = $_partner
$: partnerId = $_partnerId
let form = {
  email: "",
}

onMount(async () => {
  if ($loginDisabled || $externalLogin) {
    navigate("/")
  }
})

async function submit() {
  try {
    await postPasswordResetEmails({
      ...form,
      next,
      partnerId,
      partnerOrigin: partner.origin.subdomain,
      lang,
    })
    sent = true
  } catch (err) {
    console.log(err)
    errors = err
  }
}
</script>

<TwoLayout>
  {#if !sent}
    <form on:submit|preventDefault autocomplete="off" on:submit="{submit}">
      <Heading content="{passwordResetText[lang]}" className="mb-12" />
      <div class="mb-4">
        {enterYourEmailToReceiveLoginInstructionsText[lang]}
      </div>
      <TextInput
        name="email"
        label="{emailText[lang]}*"
        errors="{errors.email}"
        bind:value="{form.email}"
        className="mb-12"
      />
      <Submit
        label="{continueText[lang]}"
        color="primary"
        className="mt-12"
        classNameLabel="font-size-xl"
      />
    </form>
  {:else}
    <Heading content="{checkYourEmailText[lang]}" className="mb-12" />
    <img src="/images/mail.svg" alt="Email sent" />
  {/if}
</TwoLayout>

<script>
import Render from "./Render.svelte"

export let elements = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
export let value = undefined
export let field = undefined

let values = value || null

function isEmpty(value) {
  return typeof value === "undefined" || value === null
}

function onChange() {
  elements.forEach((question) => {
    const fieldName = question.props.name
    const fieldValue = payload[fieldName]

    if (!isEmpty(fieldValue)) {
      const propertyName = fieldName.split(".").pop()
      values = { ...values, [propertyName]: fieldValue }
    }
  })

  value = values
}

function setValuesToPayload() {
  if (value) {
    Object.entries(value).forEach((prop) => {
      const [key, propValue] = prop
      const propKey = `${field}.${key}`

      if (isEmpty(payload[propKey]) && !isEmpty(propValue)) {
        payload[propKey] = propValue
      }
    })
  }
}

$: payload, setValuesToPayload(), onChange()
</script>

{#each elements as element}
  <Render
    actions="{actions}"
    element="{element}"
    allErrors="{allErrors}"
    bind:payload
  />
{/each}

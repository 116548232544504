<style>
</style>

<script>
import { doctorRole } from "@local/config/constants"
import API from "@local/utils/api"

const fieldContentType = "application/pdf"

export let name = undefined
export let value = undefined
export let userId = undefined
export let wfId = undefined

async function onChange(evt) {
  // const reader = new FileReader()
  // reader.onload = (readerEvt) => {
  //   upload(readerEvt.target.result).then(() => {})
  // }
  // reader.readAsDataURL(evt.target.files[0])
  const blob = await evt.target.files[0].arrayBuffer()
  await upload(blob)
}

async function upload(blob) {
  try {
    console.log({ blob })
    const { uploadUrl, url } = await API.post(`uploads/${wfId}`, {
      searchParams: { role: doctorRole },
      json: { field: name, fieldContentType, userId },
    }).json()

    value = url

    await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: { "Content-Type": fieldContentType },
    })
  } catch (e) {
    console.log(e)
  }
}
</script>

<div class="{$$props.className || ''}">
  <input
    type="file"
    name="{name}"
    on:change="{onChange}"
    multi="{false}"
    accept="{fieldContentType}"
  />
</div>

<script>
import ThreeLayout from "@shared/components/ThreeLayout.svelte"
import Render from "@local/components/Render.svelte"

export let elements = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
export let visuals = true
</script>

<ThreeLayout layoutOnly="{!visuals}">
  {#each elements as element}
    <Render
      actions="{actions}"
      element="{element}"
      allErrors="{allErrors}"
      bind:payload
    />
  {/each}
</ThreeLayout>

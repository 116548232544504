<script>
import { onMount } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"

import { getPatientWorkflowId } from "@local/config/datasource"
import { referralUtms as _referralUtms } from "@local/store/referralUtms"

export let location = {}
export let workflowKey

let queryParams
$: queryParams = queryString.parse(location.search)

async function load() {
  let referralUtmSource = queryParams.utm_source
  if (!referralUtmSource) {
    referralUtmSource = $_referralUtms?.referralUtmSource
  }

  let referralUtmCampaign = queryParams.utm_campaign
  if (!referralUtmCampaign) {
    referralUtmCampaign = $_referralUtms?.referralUtmCampaign
  }

  const query = new URLSearchParams()
  if (referralUtmSource) {
    query.set("utm_source", referralUtmSource)
  }
  if (referralUtmCampaign) {
    query.set("utm_campaign", referralUtmCampaign)
  }

  try {
    const { id } = await getPatientWorkflowId(workflowKey, {
      referralUtmSource,
      referralUtmCampaign,
    })
    navigate(
      `/workflows/${id}${query.toString() ? `?${query.toString()}` : ""}`,
      {
        replace: true,
      }
    )
  } catch (err) {
    console.log(err)
    navigate(`/${query.toString() ? `?${query.toString()}` : ""}`, {
      replace: true,
    })
  }
}

onMount(load)
</script>

<style>
.label {
  @apply leading-tight tracking-normal text-lg font-semibold cursor-pointer;
}

.checkbox {
  @apply flex-none w-6 h-6 mr-3;
}

.checkbox:checked {
  accent-color: var(--primary);
}
</style>

<script>
export let name = ""
export let label = ""
export let className = ""
export let checked = false
export let value = undefined

$: checked, onChange()

function onChange() {
  value = checked
}
</script>

<div class="flex items-center w-full {className}">
  <input id="{name}" class="checkbox" type="checkbox" bind:checked />
  <label for="{name}" class="label">{label}</label>
</div>

<svelte:head>
  <script
    type="text/javascript"
    id="pap_x2s6df8d"
    src="https://hellomd.postaffiliatepro.com/scripts/hzjgo5"
  ></script>
  <script type="text/javascript">
  PostAffTracker.setAccountId("default1")
  try {
    PostAffTracker.track()
  } catch (err) {}
  </script>
</svelte:head>

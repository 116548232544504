<script>
import Render from "./Render.svelte"

export let elementsLeft = undefined
export let elementsRight = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
</script>

<div class="flex flex-row w-full">
  <div
    class="flex flex-col w-2/3 px-12 py-12 bg-white border border-t-0 border-gray-400"
  >
    {#each elementsLeft as element}
      <Render
        actions="{actions}"
        element="{element}"
        allErrors="{allErrors}"
        bind:payload
      />
    {/each}
  </div>
  <div
    class="flex flex-col w-1/3 px-8 py-12 bg-white border border-t-0 border-l-0 border-gray-400"
  >
    {#each elementsRight as element}
      <Render
        actions="{actions}"
        element="{element}"
        allErrors="{allErrors}"
        bind:payload
      />
    {/each}
  </div>
</div>

<style>
div {
  @apply w-full;
}
label {
  @apply block;
  @apply text-base font-medium;
  @apply mb-1;
  @apply cursor-pointer;
}
input {
  @apply block;
  @apply w-full;
  @apply px-3 py-2;
  @apply leading-loose;
  @apply border-gray-300 border rounded-md;
  box-shadow: inset 0 -4px 0 0 #a0aec0;
}
input:focus {
  @apply outline-none;
  box-shadow: inset 0 -4px 0 0 #277ad2;
}
input.error {
  @apply outline-none;
  box-shadow: inset 0 -4px 0 0 #c4272a;
}
.error-hint {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import IMask from "imask"

export let label = ""
export let value = ""
export let placeholder = ""
export let name = ""
export let maskType = undefined
export let maskData = undefined
export let errors = []

let inputValue = value

const masks = {
  creditCardCvv: {
    mask: "0000",
  },
  creditCardExpiration: {
    mask: "00/00",
  },
  creditCardNumber: {
    mask: [
      {
        mask: "0000 0000 0000 0000",
        regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
        maskData: "mastercard",
      },
      {
        mask: "0000 0000 0000 0000",
        regex: "^(6011\\d{0,12})|(65\\d{0,14})",
        maskData: "discover",
      },
      {
        mask: "0000 0000 0000 0000",
        regex: "^4\\d{0,15}",
        maskData: "visa",
      },
      {
        mask: "0000 0000 0000 0000",
        maskData: undefined,
      },
    ],
    dispatch(appended, dynamicMasked) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, "")

      for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
        const re = new RegExp(dynamicMasked.compiledMasks[i].regex)
        if (number.match(re) != null) {
          return dynamicMasked.compiledMasks[i]
        }
      }
    },
  },
}

let inputEl
let imask
$: handleMaskOpts(inputEl, maskType)
function handleMaskOpts(inputEl, maskType) {
  if (!maskType || !inputEl) return imask && imask.destroy()
  if (imask) return imask.updateOptions(masks[maskType])
  imask = new IMask(inputEl, masks[maskType])
  imask.on("accept", accept)
}

function accept() {
  console.log("accept", imask)
  value = imask.unmaskedValue
  maskData = imask.masked.currentMask.maskData
}

function input(evt) {
  if (!imask) {
    inputValue = evt.target.value
    value = evt.target.value
  }
}
</script>

<div class="{$$props.class || ''}">
  {#if label}<label for="{name}">{label}</label>{/if}
  <input
    on:input="{input}"
    bind:this="{inputEl}"
    bind:value="{inputValue}"
    class:error="{errors.length > 0}"
    type="text"
    name="{name}"
    placeholder="{placeholder}"
    id="{name}"
  />
  {#if errors.length > 0}
    <div class="error-hint">{errors[0]}</div>
  {/if}
</div>

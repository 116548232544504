<script>
import { onMount, tick } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"
import { partnerId as _partnerId } from "@local/store/theme"
import { auth } from "@local/store/auth"
import { anonymousApi as API } from "@local/utils/api"

let queryParams
$: queryParams = queryString.parse(location.search)
$: authToken = queryParams.authToken
$: adminAuthToken = queryParams.adminAuthToken
$: email = queryParams.email
$: next = queryParams.next
$: partnerId = $_partnerId

onMount(async function () {
  try {
    const json = { email, authToken, adminAuthToken }
    const partnerPath = `partners/${partnerId}/`
    const tokens = await API.post(`${partnerPath}tokens`, { json }).json()
    auth(tokens)
    await tick()
    console.log({ next })
    // navigate(next || "/", { replace: true })
  } catch (err) {
    console.log(err)
  }
  navigate(next || "/", { replace: true })
})
</script>

import { writable } from "svelte/store"

const subdomain = window.location.hostname.split(".")[0]
let key = `hellomdReferral+${subdomain}`

export const referral = writable()

try {
  const initialState = JSON.parse(localStorage.getItem(key))
  if (initialState) referral.set(initialState)
} catch (err) {
  console.error(err)
}

export function setReferral(_referral) {
  if (!_referral) {
    localStorage.setItem(key, undefined)
    referral.set(undefined)
    return
  }

  localStorage.setItem(key, JSON.stringify(_referral))
  referral.set(_referral)
}

<style>
.link.primary {
  background-color: "transparent";
  border-color: "transparent";
  color: var(--primary);
}

.link.black {
  background-color: "transparent";
  border-color: "transparent";
  color: black;
}
</style>

<script>
import Icon from "@shared/components/Icon.svelte"

//export let label = "Download"
export let links = []
export let className = ""
export let icon = undefined
</script>

{#each links as link}
  <a
    href="{link.url}"
    target="_blank"
    class="block {`hover:underline ${className}`}"
    >{#if icon}
      <Icon class="mr-2 align-middle">{icon}</Icon>
    {/if}{link.label}</a
  >
{/each}

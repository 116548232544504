<script>
import { tick } from "svelte"
import queryString from "query-string"
import { navigate } from "svelte-routing"

import { handleErrors } from "@local/utils/api"
import { patchPassword } from "@local/config/datasource"
import { partnerId as _partnerId } from "@local/store/theme"
import { auth } from "@local/store/auth"

import TwoLayout from "@shared/components/TwoLayout.svelte"

import TextInput from "@local/components/TextInput.svelte"
import Heading from "@local/components/Heading.svelte"
import Submit from "@local/components/Submit.svelte"

export let location = {}
export let token

let queryParams
$: queryParams = queryString.parse(location.search)
$: next = queryParams.next

$: partnerId = $_partnerId
let form = {
  password: "",
}
let errors = {}

async function submit() {
  try {
    const response = await patchPassword({
      ...form,
      token,
      partnerId,
    })

    auth(response)
    await tick()
    navigate(next, { replace: true })
  } catch (err) {
    errors = await handleErrors({ err, customErrMsgs: {} })
  }
}
</script>

<TwoLayout>
  <form on:submit|preventDefault autocomplete="off" on:submit="{submit}">
    <Heading content="Enter your new password" className="mb-12" />
    <TextInput
      name="password"
      type="password"
      label="Password*"
      bind:value="{form.password}"
      errors="{errors && errors.password}"
      className="mb-12"
    />
    <Submit label="Reset password" color="primary" className="mb-6" />
  </form>
</TwoLayout>

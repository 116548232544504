<style>
.download {
  @apply mr-2 mb-4 text-green-600;
}
</style>

<script>
import DownloadLink from "./DownloadLink.svelte"

export let label = ""
export let labelLink = ""
export let files = []
export let className = ""
</script>

<div class="mb-6">
  <h3>{label}</h3>

  <div class="{className}">
    {#each files as file}
      <div class="inline-flex">
        <span class="download">
          <DownloadLink
            links="{[{ url: file.url, label: labelLink }]}"
            icon="file_download"
          />
        </span>
        {file.path.split("/").pop()}
      </div>
    {/each}
  </div>
</div>

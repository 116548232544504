import { writable, derived } from "svelte/store"

function alpha(hex, alpha) {
  if (!hex) return "rgba(0, 0, 0, 0)"
  let bigint = parseInt(hex.replace("#", ""), 16)
  let r = (bigint >> 16) & 255
  let g = (bigint >> 8) & 255
  let b = bigint & 255
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

function lighten(hex) {
  return alpha(hex, 0.2)
}

export const partner = writable(null)
export const partnerId = writable(null)
export const primaryColor = writable(null)
export const primaryColorLighter = derived(primaryColor, lighten)
export const primaryColorAccent = writable(null)
export const logoAlt = writable(null)
export const logoPath = writable(null)
export const landingHeroPath = writable(null)
export const landingHeroMobilePath = writable(null)
export const landingHeroBgColor = writable(null)
export const landingHeroLayerBgColor = writable(null)
export const bgColor = writable(null)
export const bgPatternPath = writable(null)
export const topBar = writable(false)
export const barColor = writable(null)
export const topBarOffset = writable(null)
export const leftTopBarMessage = writable("")
export const rightTopBarMessage = writable("")
export const hideLanguageSelector = writable(false)
export const languages = writable([])
export const registrationInfoPath = writable(null)
export const registrationPageTitle = writable(null)
export const registrationPageSubTitle = writable(null)
export const landingMedicalCenterPath = writable(null)
export const trustedPaymentPath = writable(null)
export const refundPolicyPath = writable(null)
export const certificationPath = writable(null)
export const bgFunnelPath = writable(null)

export const infoBoxHeader = writable(null)
export const infoBoxP1 = writable(null)
export const infoBoxP2 = writable(null)
export const infoBoxP3 = writable(null)
export const infoSectionFontColor = writable(null)
export const linkSectionFontColor = writable(null)

export const loginDisabled = writable(false)
export const externalLogin = writable(false)
export const externalLoginCTA = writable(null)
export const externalLoginCTAUrl = writable(null)
export const externalLoginInfo = writable(null)

export const registrationDisabled = writable(false)
export const registrationPageShowInfoBox = writable(null)
export const registrationPageShowBanner = writable(null)
export const registrationPageShowTrustedPaymentBanner = writable(null)

const imgixParams = {
  auto: 'format',
  'min-w': 301,
  fit: 'crop',
  crop: 'entropy',
}

function partnerFileUrl(path) {
  return `https://SVELTE_APP_IMGIX_BASE_PATH/partners/${path}?${new URLSearchParams(imgixParams).toString()}`
}

function referralFileUrl(path) {
  return `https://SVELTE_APP_IMGIX_BASE_PATH/referrals/${path}?${new URLSearchParams(imgixParams).toString()}`
}

export function setPartner(_partner, referral = null) {
  if (_partner) {
    partner.set(_partner)
    partnerId.set(_partner._id.toString())
    primaryColor.set(_partner.origin.theme.primaryColor)
    primaryColorAccent.set(_partner.origin.theme.primaryColorAccent)
    logoAlt.set(_partner.origin.theme.logoAlt)
    logoPath.set(
      partnerFileUrl(`${_partner._id}/${_partner.origin.subdomain}/logo`)
    )
    landingHeroPath.set(
      `url(${partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/landingHero`
      )})`
    )
    landingHeroMobilePath.set(
      `url(${partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/landingHeroMobile`
      )})`
    )
    landingHeroBgColor.set(_partner.origin.theme.landingHeroBgColor)
    bgColor.set(_partner.origin.theme.bgColor)
    bgPatternPath.set(
      `url(${partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/bgPattern`
      )})`
    )
    topBar.set(_partner.origin.theme.topBar)
    topBarOffset.set(_partner.origin.theme.topBar ? "6rem" : "4rem")
    barColor.set(_partner.origin.theme.barColor ? _partner.origin.theme.barColor : "black")
    leftTopBarMessage.set(_partner.origin.theme.leftTopBarMessage)
    rightTopBarMessage.set(_partner.origin.theme.rightTopBarMessage)
    hideLanguageSelector.set(!!_partner.origin.theme.hideLanguageSelector)
    languages.set(_partner.props.languages || [])

    // showPaymentImage.set(_partner.origin.paymentPage.showBanner)
    registrationInfoPath.set(
      partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/registrationInfo`
      )
    )
    trustedPaymentPath.set(
      partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/trustedPayment`
      )
    )
    refundPolicyPath.set(
      partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/refundPolicy`
      )
    )
    certificationPath.set(
      partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/certification`
      )
    )
    landingMedicalCenterPath.set(
      `url(${partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/landingMedicalCenter`
      )})`
    )
    landingHeroLayerBgColor.set(_partner.origin.theme.landingHeroLayerBgColor)

    infoSectionFontColor.set(_partner.origin.theme.infoSectionFontColor)
    linkSectionFontColor.set(_partner.origin.theme.linkSectionFontColor)
    bgFunnelPath.set(
      `url(${partnerFileUrl(
        `${_partner._id}/${_partner.origin.subdomain}/bgFunnel`
      )})`
    )

    if (_partner.origin.registrationPage) {
      const {
        title: partnerRegistrationTitle,
        subTitle: partnerRegistrationSubTitle,
        showInfoBox: partnerShowInfoBox,
        showBanner: partnerShowBanner,
        showTrustedPaymentBanner: partnerShowTrustedPaymentBanner,
        infoBoxHeader: partnerInfoBoxHeader,
        infoBoxP1: partnerInfoBox1,
        infoBoxP2: partnerInfoBox2,
        infoBoxP3: partnerInfoBox3,
        disabled,
      } = _partner.origin.registrationPage

      if (partnerRegistrationTitle) {
        registrationPageTitle.set(partnerRegistrationTitle)
      }
      if (partnerRegistrationSubTitle) {
        registrationPageSubTitle.set(partnerRegistrationSubTitle)
      }
      if (partnerShowInfoBox) {
        registrationPageShowInfoBox.set(partnerShowInfoBox)
      }
      if (partnerShowBanner) {
        registrationPageShowBanner.set(partnerShowBanner)
      }
      if (partnerShowTrustedPaymentBanner) {
        registrationPageShowTrustedPaymentBanner.set(
          partnerShowTrustedPaymentBanner
        )
      }
      if (partnerInfoBoxHeader) {
        infoBoxHeader.set(partnerInfoBoxHeader)
      }
      if (partnerInfoBox1) {
        infoBoxP1.set(partnerInfoBox1)
      }
      if (partnerInfoBox2) {
        infoBoxP2.set(partnerInfoBox2)
      }
      if (partnerInfoBox3) {
        infoBoxP3.set(partnerInfoBox3)
      }
      registrationDisabled.set(disabled)
    }

    if (_partner.origin.loginPage) {
      const {
        externalLogin: partnerExternalLogin,
        externalLoginInfo: partnerExternalLoginInfo,
        externalLoginCTAUrl: partnerExternalLoginCTAUrl,
        externalLoginCTA: partnerExternalLoginCTA,
        disabled,
      } = _partner.origin.loginPage

      if (partnerExternalLoginInfo) {
        externalLoginInfo.set(partnerExternalLoginInfo)
      }
      if (partnerExternalLoginCTAUrl) {
        externalLoginCTAUrl.set(partnerExternalLoginCTAUrl)
      }
      if (partnerExternalLoginCTA) {
        externalLoginCTA.set(partnerExternalLoginCTA)
      }
      externalLogin.set(partnerExternalLogin)
      loginDisabled.set(disabled)
    }
  }

  if (referral) {
    if (referral.props.theme) {
      const {
        logoImage: refLogoImage,
        logoAlt: refLogoAlt,
        landingHeroImg: refLandingHeroImg,
        landingHeroLayerBgColor: refLandingHeroLayerBgColor,
      } = referral.props.theme

      if (refLandingHeroImg) {
        landingHeroPath.set(
          `url(${referralFileUrl(`${referral._id}/landingHero`)})`
        )
      }
      if (refLogoAlt) {
        logoAlt.set(refLogoAlt)
      }
      if (refLogoImage) {
        logoPath.set(referralFileUrl(`${referral._id}/logo`))
      }
      if (refLandingHeroLayerBgColor) {
        landingHeroLayerBgColor.set(refLandingHeroLayerBgColor)
      }
    }

    if (referral.props.registrationPage) {
      const {
        title: refRegistrationTitle,
        subTitle: refRegistrationSubTitle,
        showInfoBox: refShowInfoBox,
        showBanner: refShowBanner,
        showTrustedPaymentBanner: refShowTrustedPaymentBanner,
        infoBoxHeader: refInfoBoxHeader,
        infoBoxP1: refInfoBoxP1,
        infoBoxP2: refInfoBoxP2,
        infoBoxP3: refInfoBoxP3,
      } = referral.props.registrationPage

      if (refRegistrationTitle) {
        registrationPageTitle.set(refRegistrationTitle)
      }
      if (refRegistrationSubTitle) {
        registrationPageSubTitle.set(refRegistrationSubTitle)
      }
      if (refShowBanner) {
        registrationPageShowBanner.set(refShowBanner)
      }
      if (refShowInfoBox) {
        registrationPageShowInfoBox.set(refShowBanner)
      }
      if (refShowTrustedPaymentBanner) {
        registrationPageShowTrustedPaymentBanner.set(
          refShowTrustedPaymentBanner
        )
      }
      if (refInfoBoxHeader) {
        infoBoxHeader.set(refInfoBoxHeader)
      }
      if (refInfoBoxP1) {
        infoBoxP1.set(refInfoBoxP1)
      }
      if (refInfoBoxP2) {
        infoBoxP2.set(refInfoBoxP2)
      }
      if (refInfoBoxP3) {
        infoBoxP3.set(refInfoBoxP3)
      }
    }
  }
}

<script>
import { onMount, tick } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"
import { anonymousApi as API } from "@local/utils/api"
import { setReferral } from "@local/store/referral"
import { setReferralUtms } from "@local/store/referralUtms"
import { isLoggedIn, isEmailConfirmed } from "@local/store/auth"

export let location = {}
export let referralCode

let queryParams
$: queryParams = queryString.parse(location.search)

onMount(async function () {
  const { utm_source: referralUtmSource, utm_campaign: referralUtmCampaign } =
    queryParams

  const query = new URLSearchParams()
  if (referralUtmSource) query.set("utm_source", referralUtmSource)
  if (referralUtmCampaign) query.set("utm_campaign", referralUtmCampaign)

  if (referralUtmSource || referralUtmCampaign) {
    setReferralUtms({ referralUtmSource, referralUtmCampaign })
  } else {
    setReferralUtms(undefined)
  }

  try {
    const referral = await API.get(`referrals/code/${referralCode}`).json()
    setReferral(referral)
    await tick()

    if (($isLoggedIn && $isEmailConfirmed) || referral.props.skipLanding) {
      navigate(
        `/get-started${query.toString() ? `?${query.toString()}` : ""}`,
        {
          replace: true,
        }
      )
    } else {
      navigate(`/start${query.toString() ? `?${query.toString()}` : ""}`, {
        replace: true,
      })
    }
  } catch (err) {
    setReferral(undefined)
  }
})
</script>

<script>
import Render from "./Render.svelte"
export let elements = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
</script>

{#each elements as element}
  <Render
    actions="{actions}"
    element="{element}"
    allErrors="{allErrors}"
    bind:payload
  />
{/each}

<script>
import Button from "./Button.svelte"

export let enableConfirmation = false
export let confirmationMessage = "Are you sure?"
export let predefinedValue = null
export let value = null
export let color = "primary"
export let width = "full"
export let position = "center"
export let className = ""

$: disabled = value === predefinedValue

function click() {
  if (!enableConfirmation || confirm(confirmationMessage)) {
    setValue()
    disabled = true
  }
}

function setValue() {
  value = predefinedValue
}
</script>

<div class="{className}">
  <Button
    {...$$restProps}
    on:click="{click}"
    type="button"
    color="{color}"
    disabled="{disabled}"
    width="{width}"
    position="{position}"
  />
</div>

export const loginText = { en: "LOGIN", fr: "CONNEXION" }
export const logoutText = { en: "Log out", fr: "Se déconnecter" }
export const yourWorkstationText = {
  en: "Your Workstation",
  fr: "Votre poste de travail",
}
export const allRightsReservedText = {
  en: "HelloMD. All rights reserved",
  fr: "HelloMD. Tous droits réservés",
}
export const learnMoreText = { en: "Learn More", fr: "Apprendre encore plus" }
export const generalQuestionsText = {
  en: "General Questions",
  fr: "Questions Générales",
}
export const usefulLinks = { en: "Useful Links", fr: "Liens utiles" }
export const contactUs = { en: "Contact Us", fr: "Nous Contacter" }
export const privacyPolicyText = {
  en: "Privacy Policy",
  fr: "Politique de confidentialité",
}

export const addMoreText = { en: "Add more", fr: "Ajouter plus" }

export const weekdaysText = {
  en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  fr: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
}
export const verifyEmailHeadingText = {
  en: "Please check your email for a message from HelloMD",
  fr: "Un courriel de HelloMD vous attend dans votre boite de réception",
}
export const verifyEmailSubHeadingText = {
  en:
    "Then click on the link in the message to continue scheduling of your appointment.",
  fr:
    "Ensuite, cliquez sur le lien dans le message pour continuer à prendre rendez-vous.",
}
export const notSeeingItText = {
  en: "Not seeing it?",
  fr: "Vous ne le voyez pas?",
}
export const resendText = {
  en: "Resend",
  fr: "Renvoyer",
}
export const sentText = {
  en: "Sent",
  fr: "Envoyé",
}
export const emailSentText = {
  en: "Email sent",
  fr: "Courriel envoyé",
}
export const importantMessageText = {
  en: "Important Message",
  fr: "[fr] Important Message",
}
export const emailText = {
  en: "Email",
  fr: "Courriel",
}
export const passwordText = {
  en: "Password",
  fr: "Mot de passe",
}
export const nextText = {
  en: "Next",
  fr: "Suivant",
}
export const renewingOrAlreadyRegisteredText = {
  en: "Renewing or Already Registered?",
  fr: "Renouvellement ou déjà inscrit?",
}
export const createYourPasswordWithAtLeastXCharacteres = {
  en: (qty) => `create your password with at least ${qty} characters`,
  fr: (qty) => `${qty} caractères minimum`,
}
export const welcomeBackText = {
  en: "Hi there, welcome back",
  fr: "Bonjour, et bienvenue!",
}
export const signInText = {
  en: "Sign in",
  fr: "Connexion",
}
export const forgotPasswordText = {
  en: "Forgot your password?",
  fr: "Avez-vous oublié votre mot de passe?",
}
export const loginOrPasswordIncorrectText = {
  en: "Your email or password is incorrect",
  fr: "Votre courriel ou mot de passe est incorrect",
}
export const passwordResetText = {
  en: "Password Reset",
  fr: "Bon retour! Notre processus de connexion a changé",
}
export const enterYourEmailToReceiveLoginInstructionsText = {
  en: "Enter your email to receive login instructions",
  fr: "Entrez votre courriel afin de recevoirles instructions de connexion",
}
export const continueText = {
  en: "CONTINUE",
  fr: "CONTINUER",
}
export const checkYourEmailText = {
  en: "Check your email",
  fr: "Vérifier votre boîte de réception",
}
export const welcomeBackForYourVideoAppoitmentText = {
  en:
    "Welcome back for your video appointment. We need your help to verify access to your camera and microphone.",
  fr:
    "Bienvenue à votre rendez-vous en ligne. Nous avons besoin de votre aide pour vérifier l'accès à votre caméra et à votre microphone.",
}
export const clickToContinueText = {
  en: "Click Continue",
  fr: "Cliquez sur Continuer",
}
export const lookUpAPopUpText = {
  en: "Look for a 'pop-up' asking to access you camera and microphone",
  fr:
    "Une fenêtre contextuelle vous demandera l'accès à votre caméra et à votre microphone",
}
export const beSureToClickOnAllowText = {
  en: "Be sure to click on 'Allow'",
  fr: "Cliquez sur 'Autoriser'",
}
export const somethingWentWrongWeCantAccessYourCameraAndMicrophoneText = {
  en:
    "Something went wrong. We can't access your camera and microphone.Let's try to solve this.",
  fr:
    "Quelque chose ne marche pas. On ne peut pas accéder votre microphone, ni caméra. Laissez nous essayer de le résoudre.",
}
export const thereAreAfewPossibleReasonsText = {
  en: "There are a few possible reasons for the problem.",
  fr: "Il y a quelques possibilités pour le problème",
}
export const yourAccidentallyDeniedAccessText = {
  en: "You accidentally denied access on the pop-up.",
  fr: "Vous avez refusé accès aux fenêtres pop-up",
}
export const ifSoYouCanClickTryAgainBelowText = {
  en: "If so, you can click Try Again below",
  fr: "Si oui, vous pouvez réessayer encore en cliquant ci-dessous",
}
export const yourCameraAndMicrophoneAreNotWorkingText = {
  en:
    "Your camera and/or microphone are not working or perhaps your device is configured to deny us access.",
  fr:
    "Soit votre microphone ou caméra ne fonctionne pas. Sinon,peut être votre appareil est configurer pour nous bloquer accès",
}
export const ifYouAreStillHavingTroubleWeSuggestClickingGetHelpText = {
  en:
    "If you are still having trouble, we suggest clicking 'Get Help' below and start chatting with our Support team to find a quick solution.",
  fr:
    "Si vous avez encore de la misère , on suggère de cliquer sur 'obtenir de l'aide' ci-dessous et de commencer un conversation avec notre service de clientèle. Merci pour votre patience",
}
export const itCouldBeHelpfulToShareTheTecnicalText = {
  en:
    "It could be helpful to share the technical info below in red. Please copy and paste this into the chat box for the representative. Thank you for your patience.",
  fr:
    "Il pourrait etre utile de partager les informations ci-dessous en rouge. Veuillez copier et coller ceci dans la boîte de discussion. Merci pour votre patience.",
}
export const noteText = {
  en: "Note",
  fr: "Noter",
}
export const getHelpText = {
  en: "Get Help",
  fr: "Obtenir de l'aide",
}
export const tryAgainText = {
  en: "Try Again",
  fr: "Réessayer Encore",
}
export const greatEverythingIsAllSetText = {
  en: "Great. Everything is all set!",
  fr: "Super. Tout est prêt",
}
export const joinText = {
  en: "Join",
  fr: "Rejoindre",
}
export const youCanNowProceedText = {
  en:
    "You can now proceed. Please click on 'Join' below and a HelloMD representative will greet you soon after and get you connected with the practitioner.",
  fr:
    "Vous pouvez maintenant poursuivre. Cliquez sur 'Rejoindre' ci-dessous. Un agent de HelloMD vous accueillera sans délai et vous connectera à votre conseiller.",
}
export const timeText = {
  en: "Time",
  fr: "Heure",
}
export const dateText = {
  en: "Date",
  fr: "Date",
}
export const checkYourEmailForInstructionsText = {
  en: "Check your e-mail for instructions",
  fr: "Veuillez vérifié votre courriel pour les instructions",
}
export const morningText = {
  en: "Morning, ",
  fr: "Bon matin, ",
}
export const howCanWeHelpYouTodayText = {
  en: "How can we help you today?",
  fr: "Comment puis-je vous aider aujourd'hui?",
}
export const scheduleAFollowUpAppointmentText = {
  en: "Schedule a Follow-up appointment",
  fr: "[fr] Schedule a Follow-up appointment",
}
export const renewYourMedicalDocumentText = {
  en: "Renew your medical document",
  fr: "Renouveler votre document médical",
}
export const startEducatorProcessText = {
  en: "Get an educator appointment",
  fr: "Get an educator appointment", //[fr]
}

export const wereYouPreviouslyDeniedText = {
  en: "Were you previously denied?",
  fr: "Étiez-vous refusé auparavant?",
}
export const talkWithAPractitionerAgainText = {
  en: "Talk with a practitioner again",
  fr: "Parlez à nouveau avec un praticien",
}
export const talkWithAnEducatorText = {
  en: "Talk with an educator",
  fr: "Parlez avec un educator",
}
export const scheduleYourRenewalNowText = {
  en: "Schedule your Renewal Now",
  fr: "Planifie votre renouvellement maintenant",
}
export const haveQuestionsText = {
  en: "Have questions?",
  fr: "Avez-vous des questions?",
}
export const yourMedicalPortalText = {
  en: "Your medical portal",
  fr: "Votre portail médical",
}
export const yourEducatorPortalText = {
  en: "Your educator portal",
  fr: "Votre portail éducateur",
}
export const continueWithYourRenewalProcessText = {
  en: "Continue with your renewal process",
  fr: "Continue avec le processus de renouvellement",
}
export const continueWithYourFollowUpProcessText = {
  en: "Continue with your follow up process",
  fr: "[fr] Continue with your follow up process",
}
export const continueWithYourEducatorProcessText = {
  en: "Continue with your educator process",
  fr: "Continue avec le processus de éducateur",
}
export const continueWithYourSurveyProcessText = {
  en: "Continue with your survey process",
  fr: "[fr] Continue with your survey process",
}
export const startSurveyText = {
  en: "Start survey",
  fr: "[fr] Start survey",
}
export const yourMedicalDocumentText = {
  en: "Your medical document",
  fr: "Votre document médical",
}
export const yourConsultNotesText = {
  en: "Your consult notes",
  fr: "Vos notes de consultations",
}
export const yourEducatorNotesText = {
  en: "Your educator notes",
  fr: "Vos notes de éducateur",
}
export const expiredText = {
  en: "Expired",
  fr: "Expiré",
}
export const expiresText = {
  en: "Expires",
  fr: "Expire",
}
export const deniedText = {
  en: "Denied",
  fr: "Refusé",
}
export const onText = {
  en: "on",
  fr: "le",
}
export const viewText = {
  en: "View",
  fr: "Visionner",
}
export const liveQueueText = {
  en: "Live Queue",
  fr: "File d'attente en direct",
}
export const pastPatientsText = {
  en: "Past Patients",
  fr: "Anciens patients",
}
export const referralPatientsText = {
  en: "Referral Patients",
  fr: "Référence les patients",
}
export const reviewPatientsText = {
  en: "Review Patients",
  fr: "Examiner les patients",
}
export const scheduledPatientsText = {
  en: "Scheduled patients",
  fr: "Patients à l'horaire",
}
export const searchByThePatientNameText = {
  en: "Search by patient name or email",
  fr: "Recherche par nom de patient ou courriel",
}
export const loadMoreText = {
  en: "Load more",
  fr: "Charger plus",
}
export const awaitingPatientsText = {
  en: "Awaiting patients",
  fr: "En attente des patients",
}
export const startText = {
  en: "START",
  fr: "COMMENCER",
}
export const callText = {
  en: "CALL",
  fr: "APPELER",
}

export const youHaveNoPatientsWaitingText = {
  en: "You have no patients waiting",
  fr: "Vous n'avez pas de patients en attente",
}
export const youHaveNoConnectedPatientsText = {
  en: "You have no connected patients",
  fr: "Vous n'avez aucun patient connecté",
}
export const connectedPatientsText = {
  en: "Connected patients",
  fr: "Patients connectés",
}
export const patientWaitingText = {
  en: "Patient waiting",
  fr: "Patient en attente",
}
export const joinTheVideoCallText = {
  en: "Join the video call",
  fr: "Rejoindre l'appel vidéo",
}
export const takeScreenshotText = {
  en: "Take screenshot",
  fr: "Prendre une capture d'écran",
}
export const askPatientToShowTheIDText = {
  en: "Ask patient to show the ID in front of the camera.",
  fr: "Demandez au patient de montrer leur pièce d'identité devant la caméra",
}
export const retryText = {
  en: "Retry",
  fr: "Réessayer",
}
export const youNeedToBeAtLeastXYearsOldText = {
  en: (yearsOld) => `You need to be at least ${yearsOld} years old`,
  fr: (yearsOld) => `Vous devrez être âgé d'au moins ${yearsOld} ans`,
}
export const myMediInfoText = {
  en: "MyMedi Info",
  fr: "Info de MyMedi",
}
export const myMediInfoDescriptionText = {
  en:
    "Please remind patients to return to MyMedi to register - if they have not already. Once they register, they will receive an email from MyMedi support in 24-48 hours so that they are approved and can order. For product selection help they can call 844-633-2627. They will receive an email from us right after the medical document is posted with this information.",
  fr:
    "Veuillez rappeler aux patients de retourner sur MyMedi pour s'inscrire s'ils ne l'ont pas déjà fait. Une fois inscrits, ils recevront un e-mail du support MyMedi dans les 24 à 48 heures, puis ils pourront commencer à commander des produits. Pour obtenir de l'aide sur la sélection des produits, ils peuvent appeler le 844-633-2627. Ils recevront un e-mail de HelloMd après la publication de leur document médical contenant ces informations.",
}
export const removeText = {
  en: "Remove",
  fr: "Supprimer",
}
export const addAnotherText = {
  en: "Add another",
  fr: "Ajouter",
}
export const loadingText = {
  en: "Loading",
  fr: "Chargement en cours",
}
export const errorUploadingFileText = {
  en: "Error uploading file",
  fr: "Erreur de téléchargement du fichier",
}
export const errorUploadingFileWithSameNameText = {
  en: "The filename already exists. Please rename it and try again.",
  fr: "Ce nom de fichier existe déjà. Veuillez le renommer et réessayer.",
}
export const errorRemovingFileText = {
  en: "Error removing file",
  fr: "Erreur de suppression de fichier",
}
export const scheduleAProductDosingGuidanceConsultationText = {
  en: "Schedule a Product & Dosing Guidance Consultation",
  fr: "Planifier une consultation sur les produits et les conseils de dosage",
}
export const yourPatientPortalText = {
  en: "Your Patient Portal",
  fr: "Votre portail patient",
}
export const downloadYourProductEDosingGuidanceText = {
  en: "Download your Product & Dosing Guidance",
  fr: "Téléchargez votre guide de produit et de dosage.",
}
export const downloadCopyOfPractitionerNotesText = {
  en: "Download copy of Practitioner Notes",
  fr: "Télécharger une copie des notes de votre praticien",
}
export const downloadCopyOfPractitionerNotesOrMedicalDocumentText = {
  en: "Download copy of Practitioner Notes or Medical Document",
  fr: "Télécharger une copie des notes de votre praticien",
}
export const allOtherInquiresText = {
  en: "All other inquiries",
  fr: "Toutes autres demandes",
}
export const talkWithOurSupportTeamText = {
  en: "Talk with our Support Team",
  fr: "Discutez avec notre équipe d'assistance",
}
export const calculateMorphineText = {
  en: "Calculate morphine",
  fr: "Calculate morphine", //[fr]
}
export const totalDailyMorphineEquivalencyText = {
  en: "Total daily morphine equivalency [mg]",
  fr: "Total daily morphine equivalency [mg]", //[fr]
}

export const twoFactorAuthenticationText = {
  en: "Two-Factor Authentication",
  fr: "[fr] Two-Factor Authentication", //not necessary while 2FA is only in admin
}
export const twoFactorAuthenticationInfoMessageText = {
  en:
    "Your account is protected with two-factor authentication and we've sent you an email.\nPlease check your email and enter the code below.",
  fr:
    "[fr] Your account is protected with two-factor authentication and we've sent you an email.\n Please check your email and enter the code below.", //not necessary while 2FA is only in admin
}
export const resendTwoFactorAuthenticationCodeText = {
  en: "Resend code via email",
  fr: "[fr] Resend code via email", //not necessary while 2FA is only in admin
}
export const resendedTwoFactorAuthenticationCodeText = {
  en: "A new code was sent via email",
  fr: "[fr] A new code was sent via email", //not necessary while 2FA is only in admin
}
export const resendedErrorTwoFactorAuthenticationCodeText = {
  en: "Failure to send the new code",
  fr: "[fr] Failure to send the new code", //not necessary while 2FA is only in admin
}
export const byRegisteringYouAgreeToTheText = {
  en: "By registering you agree to the ",
  fr: "En vous inscrivant, vous acceptez aussi les ",
}
export const termsOfServiceText = {
  en: "Terms of Service",
  fr: "Conditions d'utilisation",
}
export const andText = {
  en: " & ",
  fr: " et ",
}
export const sorryVerificationFailedText = {
  en: "Sorry, your email verification failed.",
  fr: "Désolé, votre vérification a échoué.",
}
export const pleaseClickResendText = {
  en: "Please, click 'Resend' and try again.",
  fr: "Veuillez cliquer sur 'renvoyer' et réessayer.",
}
export const openText = {
  en: "OPEN",
  fr: "OUVRIR",
}
export const editText = {
  en: "EDIT",
  fr: "MODIFIER",
}
<script>
import { getContext } from "svelte"
import { TABS } from "./Tabs.svelte"

export let className = ""

const panel = {}
const { registerPanel, selectedPanel } = getContext(TABS)

registerPanel(panel)
</script>

{#if $selectedPanel === panel}
  <div class="flex flex-col flex-1 w-full {className}">
    <slot />
  </div>
{/if}

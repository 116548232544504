<script>
import { link } from "svelte-routing"
export let content = ""
export let url = ""
export let className = ""
</script>

<a
  class="w-full px-6 py-4 bg-blue-600 text-white uppercase font-bold {className} text-center"
  use:link
  href="{url}"
  target="_blank"
>
  {content}
</a>

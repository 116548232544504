<style>
h3 {
  @apply text-base font-medium text-center uppercase;
  @apply mb-6;
}
section {
  @apply w-full;
  @apply px-6 pt-6 pb-8;
}

section.gray {
  background-color: #fafafa; /* bg-gray-50 */
}

section.primary {
  border-color: var(--primary);
  border-style: solid;
  border-width: medium;
  background-color: var(--primary-lighter);
}
</style>

<script>
export let title
export let primary = false
$: gray = !primary
</script>

<h3>{title}</h3>
<section class="{$$props.class || ''}" class:primary class:gray>
  <slot />
</section>

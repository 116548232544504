<style>
.background {
  @apply flex items-center justify-center;
  @apply fixed top-0 left-0 z-20;
  @apply w-full min-h-screen;
  @apply bg-black bg-opacity-50;
}
.modal {
  @apply bg-white rounded-sm shadow-sm px-8 py-6 max-w-xl;
  min-width: 50vw;
  max-height: 75vh;
  overflow: auto;
}
</style>

<script>
import Button from "./Button.svelte"
import Render from "./Render.svelte"

export let elements = []
export let buttonLabel = "Show"
export let payload = undefined
export let actions = undefined
export let allErrors = undefined
export let className = ""
export let icon = undefined

let showModal = false
</script>

<div class="{className}">
  <Button
    type="button"
    color="primary"
    outlined="{false}"
    filled="{false}"
    label="{buttonLabel}"
    leftIcon="{icon}"
    iconSize="large"
    on:click="{() => (showModal = true)}"
  />
</div>

{#if showModal}
  <div class="background">
    <div class="modal">
      <Button
        className="button-sm hover:bg-blue-200 float-right"
        type="button"
        color="primary"
        outlined="{false}"
        filled="{false}"
        label=""
        leftIcon="close"
        on:click="{() => (showModal = false)}"
      />
      {#each elements as element}
        <Render
          actions="{actions}"
          element="{element}"
          allErrors="{allErrors}"
          bind:payload
        />
      {/each}
    </div>
  </div>
{/if}

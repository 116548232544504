<script>
import Render from "@local/components/Render.svelte"

export let elements = undefined
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
</script>

<div class="{$$props.className || ''}" class:hidden="{elements.length === 0}">
  {#each elements as element}
    <Render
      actions="{actions}"
      element="{element}"
      allErrors="{allErrors}"
      bind:payload="{payload}"
    />
  {/each}
</div>

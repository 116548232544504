<script>
import { navigate } from "svelte-routing"
import queryString from "query-string"

import { getProps } from "@local/config/datasource"
import { currentUser } from "@local/store/auth"
import { workflowKey } from "@shared/store/workflowKey"

export let location = {}

let queryParams
$: queryParams = queryString.parse(location.search)

$: getStartedPath = `/workflows/by-key/${$workflowKey}`

let promise = (async function () {
  const props = await getProps()

  if (props.ongoingWfs.length > 0) {
    const wf = props.ongoingWfs[0]
    navigate(`/workflows/${wf.id}`, { replace: true })
    return
  }

  const query = new URLSearchParams()
  if (queryParams.utm_source) query.set("utm_source", queryParams.utm_source)
  if (queryParams.utm_campaign)
    query.set("utm_campaign", queryParams.utm_campaign)

  if (!props.workflowId) {
    if ($currentUser.roles.includes("patient")) {
      navigate(
        `${getStartedPath}${query.toString() ? `?${query.toString()}` : ""}`,
        {
          replace: true,
        }
      )
    } else {
      navigate("/doctor-workstation", { replace: true })
    }
  } else {
    navigate(`/${query.toString() ? `?${query.toString()}` : ""}`, {
      replace: true,
    })
  }

  return []
})()
</script>

{#await promise then []}
  <div></div>
{/await}

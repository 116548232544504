<style>
.wrapper {
  @apply w-full relative;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import Select from "./_Select.svelte"

export let name = undefined
export let label = undefined
export let value = undefined

export let className = ""
export let options = []
export let disabled = false
export let includeEmpty = false

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0
</script>

<div class="wrapper {className}">
  <Select
    name="{name}"
    label="{label}"
    hasErrors="{hasErrors}"
    options="{options}"
    disabled="{disabled}"
    includeEmpty="{includeEmpty}"
    bind:value
  />
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
</div>

<script>
import { onMount } from "svelte"
import { navigate } from "svelte-routing"
import queryString from "query-string"

export let location = {}
export let to = ""
export let replace = false
export let keepNext = false

let queryParams
$: queryParams = queryString.parse(location.search)

onMount(() => {
  const query = new URLSearchParams()
  if (queryParams.utm_source) query.set("utm_source", queryParams.utm_source)
  if (queryParams.utm_campaign)
    query.set("utm_campaign", queryParams.utm_campaign)

  if (!keepNext) {
    navigate(`${to}${query.toString() ? `?${query.toString()}` : ""}`, {
      replace,
    })
    return
  }

  query.set("next", location.pathname)
  navigate(`${to}?${query.toString()}`, {
    replace,
  })
})
</script>

<style>
.wrapper {
  @apply w-full relative;
}
.input-wrapper {
  @apply w-full border-0 border-b-4 rounded relative;
  background-color: #f3f3f3;
  border-color: #757575;
}
.input-wrapper.disabled {
  background-color: #dcdcdc;
  border-color: #9b9b9b;
}
.input-wrapper.focused {
  border-color: var(--primary);
}
.input-wrapper.has-errors {
  border-color: #c4272a;
}
.input {
  @apply w-full bg-transparent border-0 cursor-default outline-none pt-6 pb-1 text-black text-lg pl-4 pr-8 ease-in duration-100 appearance-none;
  line-height: 1.5rem;
}
.input:disabled {
  @apply cursor-not-allowed;
  color: #757575;
}
.label {
  @apply w-full text-lg pt-4 pb-4 pl-4 pr-8 ease-in duration-100 absolute top-0 truncate pointer-events-none;
  color: #9b9b9b;
  line-height: 1.25rem;
}
.label.disabled {
  @apply cursor-not-allowed;
  color: #757575;
}
.label.expanded {
  @apply pb-1 pt-2 text-xs leading-none;
}
.label.focused {
  color: var(--primary);
}
.options {
  @apply bg-white shadow-xl pt-2 overflow-y-scroll absolute z-10 w-full;
  max-height: 27.5rem;
}
.option {
  @apply flex items-center py-2 px-4 cursor-pointer;
}
.option.hover {
  background-color: #f3f3f3;
}
.caret {
  @apply flex justify-center items-center absolute top-0 right-0 h-13 w-8 text-lg;
  color: #757575;
}
.caret.focused {
  @apply transform rotate-180 duration-300 ease-in;
  color: var(--primary);
}
</style>

<script>
import { createEventDispatcher } from "svelte"

export let name = ""
export let label = ""
export let value = undefined
export let hasErrors = false
export let options = []
export let disabled = false
export let includeEmpty = false
export let className = ""
export let icon = undefined
export let iconClickable = undefined

const dispatch = createEventDispatcher()

let inputEl

let focused = false
$: expanded = value || focused
$: error = !focused && hasErrors
$: hasIcon = !!icon

let hovering = 0

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function focus() {
  focused = true
}

function blur() {
  focused = false
}
$: console.log()
</script>

<div class="wrapper {className}">
  <div class="flex items-center">
    <div
      class="input-wrapper"
      class:focused="{focused}"
      class:has-errors="{hasErrors}"
      class:disabled="{disabled}"
    >
      {#if label}
        <label
          for="{name}"
          class="label"
          class:focused="{focused}"
          class:expanded="{expanded}"
          class:disabled="{disabled}"
        >
          {label}
        </label>
      {/if}
      <select
        bind:this="{inputEl}"
        class="input"
        id="{name}"
        on:focus="{focus}"
        on:blur="{blur}"
        name="{name}"
        bind:value="{value}"
        disabled="{disabled}"
      >
        {#if includeEmpty}
          <option value></option>
        {/if}
        {#each options as option, i}
          <option value="{option.value || option}">
            {option.label || option}
          </option>
        {/each}
      </select>
      <i
        class="caret material-icons"
        class:focused="{focused}"
        class:disabled="{disabled}"
      >
        arrow_drop_down
      </i>
    </div>
    {#if hasIcon}
      <i
        class="mx-2 icon material-icons"
        class:clickable="{iconClickable}"
        on:click="{iconClicked}"
      >
        {icon}
      </i>
    {/if}
  </div>
</div>

<style>
.buttons {
  @apply grid gap-4;
}
.buttons.columns-1 {
  @apply grid-cols-1;
}
.buttons.columns-2 {
  @apply grid-cols-2;
}
.buttons.columns-3 {
  @apply grid-cols-3;
}
.buttons.columns-4 {
  @apply grid-cols-4;
}
.buttons.columns-5 {
  @apply grid-cols-5;
}
.buttons.columns-6 {
  @apply grid-cols-6;
}
.buttons.columns-10 {
  @apply grid-cols-10;
}
.buttons.columns-11 {
  @apply grid-cols-11;
}
.buttons.columns-12 {
  @apply grid-cols-12;
}
.button {
  @apply h-15 select-none;
}
.input {
  @apply hidden;
}
.label {
  @apply flex flex-row items-center w-full h-full bg-white font-normal cursor-pointer rounded;
  font-size: 1.125rem;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}
.input:disabled + .label {
  @apply cursor-not-allowed;
  color: #757575;
  background-color: #dcdcdc;
}
.input:checked:disabled + .label {
  @apply cursor-not-allowed;
  background-color: #dcdcdc;
}
.input:checked + .label {
  @apply font-medium;
  background-color: var(--primary-lighter);
  color: var(--primary);
}
.icon {
  @apply flex-none ml-3;
}
.error {
  @apply px-4 mt-1 text-xs leading-none;
  color: #c4272a;
}
</style>

<script>
import { onMount } from "svelte"
import SubHeading from "./SubHeading.svelte"
import TextArea from "./_TextArea.svelte"

export let label = ""
export let name = ""
export let value = undefined
export let className = ""
export let options = []
export let multi = false
export let columns = 3
export let otherOption = null
export let otherLabel = null
export let noneOption = null
export let disabled = false

let _errors
export { _errors as errors }
$: errors = _errors || []
$: hasErrors = errors.length > 0

let group = loadGroup()
let other = loadOther()
let otherChecked = multi && other

function loadGroup() {
  let _value = value || []
  let _group = []

  if (multi) {
    _group = _value.filter((x) => options.map((x) => x.value).includes(x))
    if (noneOption?.value && _value.includes(noneOption.value)) {
      _group.push(noneOption.value)
    }
  } else {
    if (noneOption?.value && _value.includes(noneOption.value)) {
      _group = noneOption.value
    } else {
      _group = value
    }
  }
  return _group
}

function loadOther() {
  let _group = null
  let _value = value || []
  if (multi) {
    if (noneOption?.value && _value.includes(noneOption.value)) {
      _value.splice((value || []).indexOf(noneOption.value))
    }
    _group =
      _value.filter((x) => !options.map((x) => x.value).includes(x))[0] || null
  }
  return _group
}

function handleOther() {
  if (!otherChecked) {
    other = null
  }
}

function onChange() {
  if (multi) {
    if (noneOption?.value) {
      let diff = group.filter((x) => !value.includes(x))[0]
      if (diff === noneOption.value) {
        group = [noneOption.value]
      } else {
        let i = group.indexOf(noneOption.value)
        if (i > -1) {
          group.splice(i, 1)
        }
      }
      value = group
    } else {
      value = [...group, ...(otherChecked && other ? [other] : [])]
    }
  } else {
    value = group !== undefined ? group : null
  }
}

$: otherChecked, handleOther()
$: other, group, onChange()

function getIcon(label) {
  const [_, iconType] = label.match(/\[(.*)\]/) || []
  return iconType
}

function removeIcon(label) {
  return label.replace(/\[(.*)\]/, "")
}
</script>

<div class="w-full {className}" id="{name}">
  <SubHeading className="mb-8" content="{label}" />
  <div
    class="buttons"
    class:columns-1="{columns === 1}"
    class:columns-2="{columns === 2}"
    class:columns-3="{columns === 3}"
    class:columns-4="{columns === 4}"
    class:columns-5="{columns === 5}"
    class:columns-6="{columns === 6}"
    class:columns-10="{columns === 10}"
    class:columns-11="{columns === 11}"
    class:columns-12="{columns === 12}"
  >
    {#each options as option, i}
      <div class="button">
        {#if multi}
          <input
            type="checkbox"
            class="input"
            bind:group="{group}"
            id="{`${name}-${i}`}"
            value="{option.value}"
            disabled="{disabled}"
          />
        {:else}
          <input
            type="radio"
            class="input"
            bind:group="{group}"
            id="{`${name}-${i}`}"
            value="{option.value}"
            disabled="{disabled}"
          />
        {/if}
        <label for="{`${name}-${i}`}" class="label">
          {#if getIcon(option.label) === "ok"}
            <i class="icon material-icons">check</i>
            <div class="flex-1 text-center">{removeIcon(option.label)}</div>
          {:else if getIcon(option.label) === "notOk"}
            <i class="icon material-icons">close</i>
            <div class="flex-1 text-center">{removeIcon(option.label)}</div>
          {:else if getIcon(option.label) === "checkbox"}
            <i class="icon material-icons">
              {#if (!multi && group === option.value) || (multi && (group || []).includes(option.value))}
                check_box
              {:else}check_box_outline_blank{/if}
            </i>
            <div class="flex-1 text-center">{removeIcon(option.label)}</div>
          {:else}
            <div class="flex-1 text-center">{option.label}</div>
          {/if}
        </label>
      </div>
    {/each}
    {#if noneOption}
      <div class="button">
        {#if multi}
          <input
            type="checkbox"
            class="input"
            bind:group="{group}"
            id="{`${name}-none`}"
            value="{noneOption.value}"
            disabled="{disabled}"
          />
        {:else}
          <input
            type="radio"
            class="input"
            bind:group="{group}"
            id="{`${name}-none`}"
            value="{noneOption.value}"
            disabled="{disabled}"
          />
        {/if}
        <label for="{`${name}-none`}" class="label">
          {#if getIcon(noneOption.label) === "ok"}
            <i class="icon material-icons">check</i>
            <div class="flex-1 text-center">{removeIcon(noneOption.label)}</div>
          {:else if getIcon(noneOption.label) === "notOk"}
            <i class="icon material-icons">close</i>
            <div class="flex-1 text-center">{removeIcon(noneOption.label)}</div>
          {:else if getIcon(noneOption.label) === "checkbox"}
            <i class="icon material-icons">
              {#if (group || []).includes(noneOption.value)}
                check_box
              {:else}check_box_outline_blank{/if}
            </i>
            <div class="flex-1 text-center">{removeIcon(noneOption.label)}</div>
          {:else}
            <div class="flex-1 text-center">{noneOption.label}</div>
          {/if}
        </label>
      </div>
    {/if}
    {#if multi && otherOption}
      <div class="button">
        <input
          type="checkbox"
          class="input"
          bind:checked="{otherChecked}"
          id="{`${name}-other`}"
          disabled="{disabled}"
        />
        <label for="{`${name}-other`}" class="label">
          {#if getIcon(otherOption.label) === "ok"}
            <i class="icon material-icons">check</i>
            <div class="flex-1 text-center">
              {removeIcon(otherOption.label)}
            </div>
          {:else if getIcon(otherOption.label) === "notOk"}
            <i class="icon material-icons">close</i>
            <div class="flex-1 text-center">
              {removeIcon(otherOption.label)}
            </div>
          {:else if getIcon(otherOption.label) === "checkbox"}
            <i class="icon material-icons">
              {#if otherChecked}check_box{:else}check_box_outline_blank{/if}
            </i>
            <div class="flex-1 text-center">
              {removeIcon(otherOption.label)}
            </div>
          {:else}
            <div class="flex-1 text-center">{otherOption.label}</div>
          {/if}
        </label>
      </div>
    {/if}
  </div>
  {#if otherChecked}
    <div class="mt-6">
      <TextArea
        name="{name}"
        label="{otherLabel}"
        hasErrors="{hasErrors}"
        bind:value="{other}"
        disabled="{disabled}"
      />
    </div>
  {/if}
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
</div>

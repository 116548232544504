<style global>
@tailwind base;
@tailwind components;
@tailwind utilities;

:global(.type-header) {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -1.16px;
}

:global(.type-sub-header) {
  font-size: 23px;
  font-weight: 600;
  letter-spacing: -0.55px;
}

:global(.type-paragraph) {
  font-size: 21px;
  font-weight: 400;
  letter-spacing: -0.51px;
}

@layer base {
  body {
    @apply font-normal;
    @apply tracking-tight;
  }
}
</style>

<script>
import {
  primaryColor,
  primaryColorLighter,
  primaryColorAccent,
  landingHeroPath,
  landingHeroMobilePath,
  landingHeroBgColor,
  landingMedicalCenterPath,
  landingHeroLayerBgColor,
  bgPatternPath,
  bgColor,
  barColor,
  topBarOffset,
  bgFunnelPath,
} from "../store/theme"

function updateTheme(
  primaryColor,
  primaryColorAccent,
  primaryColorLighter,
  landingHeroPath,
  landingHeroMobilePath,
  landingHeroBgColor,
  landingHeroLayerBgColor,
  bgPatternPath,
  bgColor,
  topBarOffset,
  barColor,
  landingMedicalCenterPath,
  bgFunnelPath
) {
  document.documentElement.style.setProperty("--primary", primaryColor)
  document.documentElement.style.setProperty(
    "--primary-lighter",
    primaryColorLighter
  )
  document.documentElement.style.setProperty(
    "--primary-accent",
    primaryColorAccent
  )
  document.documentElement.style.setProperty(
    "--landing-hero-path",
    landingHeroPath
  )
  document.documentElement.style.setProperty(
    "--landing-hero-layer-bg-color",
    landingHeroLayerBgColor
  )
  document.documentElement.style.setProperty(
    "--landing-hero-mobile-path",
    landingHeroMobilePath
  )
  document.documentElement.style.setProperty(
    "--landing-hero-bg-color",
    landingHeroBgColor
  )
  document.documentElement.style.setProperty("--bg-pattern-path", bgPatternPath)
  document.documentElement.style.setProperty("--bg-color", bgColor)
  document.documentElement.style.setProperty("--top-bar-offset", topBarOffset)
  document.documentElement.style.setProperty(
    "--landing-medical-center-path",
    landingMedicalCenterPath
  )
  document.documentElement.style.setProperty("--bar-color", barColor)
  document.documentElement.style.setProperty("--bg-funnel-path", bgFunnelPath)
}

$: updateTheme(
  $primaryColor,
  $primaryColorAccent,
  $primaryColorLighter,
  $landingHeroPath,
  $landingHeroMobilePath,
  $landingHeroBgColor,
  $landingHeroLayerBgColor,
  $bgPatternPath,
  $bgColor,
  $topBarOffset,
  $barColor,
  $landingMedicalCenterPath,
  $bgFunnelPath
)
</script>

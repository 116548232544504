<script>
import GroupElements from "@shared/components/GroupElements.svelte"
import Render from "@local/components/Render.svelte"

export let elements = []
export let allErrors = undefined
export let payload = undefined
export let actions = undefined

export let columnClasses = ""
export let visuals = true
</script>

<GroupElements columnClasses="{columnClasses}" visuals="{visuals}">
  {#each elements as element}
    <Render
      actions="{actions}"
      element="{element}"
      allErrors="{allErrors}"
      bind:payload
    />
  {/each}
</GroupElements>

<style>
.wrapper {
  @apply flex flex-1 flex-col;
  @apply w-full max-w-screen-xl;
  @apply mx-auto;
}

.column {
  @apply flex flex-1 flex-col;
  @apply mx-auto;
}

.column.visuals {
  @apply bg-white;
  @apply border border-t-0 border-gray-400;
}
</style>

<script>
export let columnClasses =
  "w-full md:w-2/3 px-4 py-8 md:px-24 md:py-16 lg:px-48"
export let visuals = true
</script>

<div class="wrapper">
  <div class="column {columnClasses}" class:visuals>
    <slot />
  </div>
</div>

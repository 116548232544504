<style>
.avatar {
  @apply text-4xl;
}

.menu-wrapper {
  @apply absolute bg-white inset-x-0 shadow-md py-1 z-50;
  top: 100%;
}

@screen md {
  .menu-wrapper {
    @apply left-auto;
  }
}

.menu-item {
  @apply whitespace-no-wrap text-base;
}

.menu-item > a,
.menu-item > span {
  @apply block w-full px-4 leading-loose cursor-pointer;
}

.menu-item > a:hover,
.menu-item > span:hover {
  background-color: var(--primary-lighter);
}
</style>

<script>
import { link } from "svelte-routing"

import { currentUser, deauth } from "../../store/auth"

import { logoutText, yourWorkstationText } from "@shared/utils/translations"
import { workflowLang } from "@shared/store/workflowKey"

let opened = false
let showWorkstation = $currentUser.roles.some((role) =>
  ["doctor", "educator"].includes(role)
)

function clickOutside(node, { enabled: initialEnabled, cb }) {
  const handleOutsideClick = ({ target }) => {
    if (!node.contains(target)) {
      cb()
    }
  }

  function update({ enabled }) {
    if (enabled) {
      window.addEventListener("click", handleOutsideClick)
    } else {
      window.removeEventListener("click", handleOutsideClick)
    }
  }

  update({ enabled: initialEnabled })

  return {
    update,
    destroy() {
      window.removeEventListener("click", handleOutsideClick)
    },
  }
}

function close() {
  opened = false
}
</script>

<div
  use:clickOutside="{{ enabled: opened, cb: close }}"
  class="flex items-center justify-center h-full md:relative"
>
  <div
    class="flex flex-row items-center justify-center w-12 h-12 text-white bg-gray-500 rounded-full shadow-inner cursor-pointer hover:opacity-75"
    on:click="{() => (opened = !opened)}"
  >
    <i class="avatar material-icons">person</i>
  </div>
  {#if opened}
    <ul class="menu-wrapper" on:click="{close}">
      {#if showWorkstation}
        <li class="menu-item">
          <a
            href="/doctor-workstation"
            use:link
          >{yourWorkstationText[$workflowLang]}</a>
        </li>
      {/if}
      <li class="menu-item">
        <span on:click="{deauth}">{logoutText[$workflowLang]}</span>
      </li>
    </ul>
  {/if}
</div>

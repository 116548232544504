<style>
.wrapper {
  @apply w-full mb-16 bg-white rounded-2xl;
  box-shadow: 0 0 3.5rem 0 rgba(0, 0, 0, 0.1);
}
.date-wrapper {
  @apply grid grid-cols-2 gap-6 p-6;
}
.date-label {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1.75;
}
.details {
  @apply py-4 px-6 border-t-2 border-gray-200;
}
/* .details strong {
  @apply font-medium;
  color: var(--primary);
} */
</style>

<script>
import format from "date-fns/format"
import { workflowLang } from "@shared/store/workflowKey"
import {
  timeText,
  dateText,
  checkYourEmailForInstructionsText,
} from "@shared/utils/translations"
import { fr, enUS as en } from "date-fns/locale"

export let schedule = undefined

$: localeLang = $workflowLang === "en" ? en : fr
$: time = format(new Date(schedule.startsAt), "h:mm aaa")
$: date = format(new Date(schedule.startsAt), "LLLL, d, yyyy", {
  locale: localeLang,
})
</script>

<div class="wrapper">
  <div class="date-wrapper">
    <div>
      <div class="date-label">{timeText[$workflowLang]}</div>
      <div class="type-paragraph">{time}</div>
    </div>
    <div>
      <div class="date-label">{dateText[$workflowLang]}</div>
      <div class="type-paragraph">{date}</div>
    </div>
  </div>
  <div class="details type-paragraph">
    {checkYourEmailForInstructionsText[$workflowLang]}
  </div>
</div>

<script>
import Section from "./payment/Section.svelte"
import Summary from "./payment/Summary.svelte"
import Coupon from "./payment/Coupon.svelte"
import TextInput from "./payment/TextInput.svelte"
import Button from "./payment/Button.svelte"
import {
  partner as _partner,
  trustedPaymentPath,
  certificationPath,
  refundPolicyPath,
} from "@local/store/theme"

export let value = {
  cardNumber: "",
  cardCvv: "",
  cardExpiration: "",
  zipcode: "",
  couponCode: "",
}
export let amount
export let wfId
export let productName
export let couponImagePath
let coupon = {}

$: discountValue = coupon.value || 0
$: total = amount - discountValue

let _errors = {}
export { _errors as errors }

let cardType = undefined
</script>

<div class="mt-6">
  <Section class="mb-2" title="">
    <Summary
      amount="{amount}"
      coupon="{coupon}"
      productName="{productName}"
      img="{$certificationPath}"
    />
  </Section>
  <Coupon
    class="mb-16"
    wfId="{wfId}"
    amount="{amount}"
    bind:couponCode="{value.couponCode}"
    bind:coupon="{coupon}"
  />

  {#if couponImagePath}
    <div class="w-full mb-8">
      <img src="{couponImagePath}" alt="Coupon Code" />
    </div>
  {/if}

  <div class="w-full mb-16 mt-8">
    <img src="{$refundPolicyPath}" alt="Refund Policy" />
  </div>

  <Section class="mb-12" title="Please enter your payment details below">
    <div class="flex flex-row justify-between items-center mb-8">
      <h4 class="font-medium text-base">Credit Card</h4>
      <div class="flex flex-row gap-1">
        <img
          src="/images/visa.svg"
          alt="Visa"
          class="h-6"
          class:opacity-50="{cardType && cardType !== 'visa'}"
        />
        <img
          src="/images/mastercard.svg"
          alt="Mastercard"
          class="h-6"
          class:opacity-50="{cardType && cardType !== 'mastercard'}"
        />
        <img
          src="/images/discover.svg"
          alt="Discover"
          class="h-6"
          class:opacity-50="{cardType && cardType !== 'discover'}"
        />
      </div>
    </div>
    <TextInput
      label="Card Number"
      name="cardNumber"
      maskType="{'creditCardNumber'}"
      bind:value="{value.cardNumber}"
      bind:maskData="{cardType}"
      errors="{(_errors || {}).cardNumber || []}"
      class="mb-4"
    />
    <div class="flex flex-col gap-4 lg:flex-row lg:gap-2">
      <TextInput
        label="Exp Date"
        name="cardExpiration"
        maskType="{'creditCardExpiration'}"
        bind:value="{value.cardExpiration}"
        errors="{(_errors || {}).cardExpiration || []}"
      />
      <TextInput
        label="CVV"
        name="cardCvv"
        maskType="{'creditCardCvv'}"
        bind:value="{value.cardCvv}"
        errors="{(_errors || {}).cardCvv || []}"
      />
      <TextInput
        label="Zipcode"
        name="zipcode"
        bind:value="{value.zipcode}"
        errors="{(_errors || {}).zipcode || []}"
      />
    </div>
  </Section>

  <div class="mb-10">
    <img src="{$trustedPaymentPath}" alt="Secure Payment" />
  </div>

  <div class="flex flex-row justify-end">
    <Button>
      <i class="icon material-icons"> lock </i>
      Pay ${total.toFixed(2)}
    </Button>
  </div>
</div>

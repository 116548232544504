<script>
import { Router, Route } from "svelte-routing"

import Container from "@local/components/layout/Container.svelte"
import { setPartner } from "@local/store/theme"
import { isLoggedIn, isEmailConfirmed } from "@local/store/auth"
import { setWorkflowKey, workflowLang } from "@shared/store/workflowKey"
import { getSubdomain } from "@local/config/datasource"
import Tracking from "@local/config/Tracking/index.svelte"
import Tailwind from "@local/config/Tailwind.svelte"
import Intercom from "@local/config/Intercom.svelte"
import Redirect from "@local/config/Redirect.svelte"

import Dashboard from "@local/routes/index.svelte"
import LoadReferral from "@local/routes/r/[referralCode].svelte"
import Auth from "@local/routes/auth.svelte"
import Login from "@local/routes/login.svelte"
import Register from "@local/routes/register.svelte"
import Verify from "@local/routes/verify.svelte"
import ForgotPassword from "@local/routes/forgot-password.svelte"
import ResetPassword from "@local/routes/reset-password.svelte"
import Welcome from "@local/routes/welcome/index.svelte"
import GetStarted from "@local/routes/get-started/index.svelte"
import DoctorWorkstation from "@local/routes/doctor-workstation/index.svelte"
import WorkflowDetail from "@local/routes/workflows/[workflowId].svelte"
import WorkflowByKey from "@local/routes/workflows/by-key/[workflowKey].svelte"

import TermsMedical from "@local/routes/terms/medical-terms-of-service.svelte"
import PrivacyPolicy from "@local/routes/terms/privacy-policy.svelte"
import Terms from "@local/routes/terms/terms-of-service.svelte"
import PartnerInfo from "@local/routes/partner-info/index.svelte"

let partner

$: subdomain = window.location.hostname.split(".")[0]

async function loadSubdomain(subdomain, _lang) {
  partner = await getSubdomain(subdomain)

  setPartner(partner)
  setWorkflowKey(partner.origin.initialWfKey)
}

$: loadSubdomain(subdomain, $workflowLang)
</script>

{#if partner}
  <Router>
    <Container>
      <Route path="r/:referralCode" component="{LoadReferral}" />
      <Route path="auth" component="{Auth}" />
      <Route path="register" component="{Register}" />
      <Route path="login" component="{Login}" />
      <Route path="forgot-password" component="{ForgotPassword}" />
      <Route path="reset-password/:token" component="{ResetPassword}" />
      <Route path="start" component="{Welcome}" />
      <Route path="verify/:token" component="{Verify}" />
      <Route path="terms/terms-of-service" component="{Terms}" />
      <Route path="terms/privacy-policy" component="{PrivacyPolicy}" />
      <Route path="terms/medical-terms-of-service" component="{TermsMedical}" />
      <Route path="partner-info/:subdomain" component="{PartnerInfo}" />
      {#if $isLoggedIn && $isEmailConfirmed}
        <Route path="doctor-workstation/*" component="{DoctorWorkstation}" />
        <Route
          path="workflows/by-key/:workflowKey"
          component="{WorkflowByKey}"
        />
        <Route path="workflows/:workflowId/*" component="{WorkflowDetail}" />
        <Route path="get-started" component="{GetStarted}" />
        <Route path="/" component="{Dashboard}" />
      {:else if $isLoggedIn && !$isEmailConfirmed}
        <Route path="verify" component="{Verify}" />
        <Route path="*" let:location>
          <Redirect
            to="/verify"
            replace="{true}"
            keepNext="{true}"
            location="{location}"
          />
        </Route>
      {:else}
        <Route path="/" let:location>
          <Redirect to="/start" replace="{true}" location="{location}" />
        </Route>
        <Route path="*" let:location>
          <Redirect
            to="/register"
            replace="{true}"
            keepNext="{true}"
            location="{location}"
          />
        </Route>
      {/if}
    </Container>
  </Router>
  <Tailwind />
  <Tracking subdomain="{subdomain}" />
  <Intercom delayMs="{3000}" />
{:else}
  <div></div>
{/if}

<script>
import TwoLayout from "@shared/components/TwoLayout.svelte"
import Render from "@local/components/Render.svelte"

export let elements = []
export let allErrors = undefined
export let payload = undefined
export let actions = undefined

export let columnClasses = ""
export let visuals = true
</script>

<TwoLayout columnClasses="{columnClasses}" visuals="{visuals}">
  {#each elements as element}
    <Render
      actions="{actions}"
      element="{element}"
      allErrors="{allErrors}"
      bind:payload
    />
  {/each}
</TwoLayout>

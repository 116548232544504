<script>
import { onMount } from "svelte"
import queryString from "query-string"

import {
  getDoctorOrEducatorWorkflow,
  patchDoctorOrEducatorWorkflow,
  patchLanguage,
} from "@local/config/datasource"

import Render from "@local/components/Render.svelte"
import { auth, currentUser } from "@local/store/auth"
import { workflowLang } from "@shared/store/workflowKey"

export let workflowId = undefined

let query = ""
let elements = []
let payload = {}
let errors = {}

let queryParams
$: queryParams = queryString.parse(location.search)

$: {
  console.info(`Switching language to ${$workflowLang}`)
  load()
  updateUserLanguage($workflowLang)
}

async function submit() {
  const buttonNode = document.querySelector("button[type='submit']")
  buttonNode && (buttonNode.disabled = true)

  errors = {}
  try {
    const data = await patchDoctorOrEducatorWorkflow(workflowId, payload)
    elements = data.elements
    payload = data.payload
  } catch (err) {
    errors = err
    const firstError = Object.keys(errors)[0]
    const el = document.querySelector(`#${firstError}`)
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" })
      return
    }
  } finally {
    buttonNode && (buttonNode.disabled = false)
  }
  window.scroll({ top: 0, behavior: "smooth" })
}

async function load() {
  const data = await getDoctorOrEducatorWorkflow(workflowId, queryParams.role)
  elements = data.elements
  payload = data.payload
}

async function updateUserLanguage(language) {
  const partnerId = $currentUser.partnerId
  const userId = $currentUser.id
  const userLanguage = $currentUser.language

  if (userLanguage !== language) {
    try {
      const tokens = await patchLanguage({ partnerId, userId, language })
      auth(tokens)
    } catch (err) {
      console.error("Unable to save language preferences", err)
    }
  }
}

onMount(load)
</script>

<form
  on:submit|preventDefault
  class="w-full"
  autocomplete="off"
  on:submit="{submit}"
>
  {#each elements as element}
    <Render element="{element}" allErrors="{errors}" bind:payload="{payload}" />
  {/each}
</form>

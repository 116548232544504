<script>
import Button from "./Button.svelte"

export let value = undefined
export let dataValue = undefined

export let className = ""
export let classNameLabel = ""

function click() {
  value = dataValue
}
</script>

<div class="w-full {className}">
  <Button
    {...$$restProps}
    on:click="{click}"
    type="submit"
    classNameLabel="{classNameLabel}"
  />
</div>

<style>
.wrapper {
  @apply flex flex-col items-center w-full bg-black h-full p-4;
}
.video-wrapper {
  @apply flex flex-col items-center relative;
  width: 100%;
}
.video {
  @apply w-full mb-2;
}
.video :global(video) {
  @apply w-full;
}
.buttons {
  @apply w-full relative flex items-center justify-center mt-6 mb-8 text-white;
}
.button {
  @apply p-4 flex justify-center items-center rounded-full mx-3 cursor-pointer;
  background-color: #757575;
}
.button:hover {
  @apply opacity-75;
}

.button.end-call {
  background-color: #fd5e53;
}
</style>

<script>
import isMobile from "ismobilejs"
import { onMount, onDestroy } from "svelte"
import Video, { connect, LocalVideoTrack } from "twilio-video"
import startOfHour from "date-fns/startOfHour"
import addSeconds from "date-fns/addSeconds"
import format from "date-fns/format"
import API from "@local/utils/api"
import { workflowLang } from "@shared/store/workflowKey"
import {
  patientWaitingText,
  joinTheVideoCallText,
} from "@shared/utils/translations"

import Button from "./Button.svelte"
import { ready } from "../store/devices"

export let accessToken = undefined
export let userId
export let workflowId

let room
let myVideoTrack
let myAudioTrack
let preferredTrackName
let videoTracks = []
let audioTracks = []

let clock = startOfHour(new Date())
let formattedClock = "00:00"
let clockInterval

let myVideoEnabled = true
let myAudioEnabled = true
let joinedCall = false

let videoContainerEl
let audiosContainerEl
let otherVideosContainerEl

async function visibilityChange() {
  if (document.visibilityState === "hidden") {
    myVideoEnabled = false
    myVideoTrack.stop()
    room.localParticipant.unpublishTrack(myVideoTrack)
  } else {
    myVideoTrack = await Video.createLocalVideoTrack({ disabled: true })
    refreshVideo()
    await room.localParticipant.publishTrack(myVideoTrack)
  }
}

function refreshAudio() {
  for (let track of audioTracks) {
    const actualTrack = track.track || track
    if (actualTrack.detach) {
      actualTrack.detach()
    }
    for (let el of audiosContainerEl.children) {
      el.remove()
    }
  }

  let peers = Array.from(room.participants.values())

  audioTracks = peers.reduce((tracks, peer) => {
    const peerTracks = Array.from(peer.audioTracks.values())
    return [...peerTracks, ...tracks]
  }, [])

  if (audioTracks.length > 0) {
    for (let track of audioTracks) {
      const actualTrack = track.track || track
      if (actualTrack.attach)
        audiosContainerEl.appendChild(actualTrack.attach())
    }
  }
}

function refreshVideo() {
  for (let track of videoTracks) {
    const actualTrack = track.track || track
    if (actualTrack.detach) {
      actualTrack.detach()
    }
    for (let el of videoContainerEl.children) {
      el.remove()
    }
    for (let el of otherVideosContainerEl.children) {
      el.remove()
    }
  }

  let peers = Array.from(room.participants.values())

  videoTracks = peers.reduce(
    (tracks, peer) => {
      const peerTracks = Array.from(peer.videoTracks.values())
      return [...peerTracks, ...tracks]
    },
    [...(myVideoEnabled ? [myVideoTrack] : [])]
  )

  let preferredTrackIdx = videoTracks.findIndex(
    (track) => track.trackName === preferredTrackName
  )

  if (preferredTrackIdx === -1) {
    preferredTrackIdx = videoTracks.length > 0 ? videoTracks.length - 1 : -1
  }

  if (videoTracks.length > 0) {
    for (let i = 0; i < videoTracks.length; i++) {
      const track = videoTracks[i]
      const actualTrack = track.track || track

      if (!track.isTrackEnabled && !track.isEnabled) continue

      if (actualTrack.attach) {
        const video = actualTrack.attach()
        video.style.transform = "scale(-1, 1)"

        if (preferredTrackIdx === i) {
          videoContainerEl.appendChild(video)
        } else {
          const newEl = video
          const evtHandler = (trackName) => () => {
            preferredTrackName = trackName
            refreshVideo()
          }
          newEl.addEventListener(
            "click",
            evtHandler(track.trackName || track.name)
          )
          otherVideosContainerEl.appendChild(newEl)
        }
      }
    }
  }
}

async function joinCall() {
  joinedCall = true

  let myTracks = await Video.createLocalTracks()

  myVideoTrack = myTracks.find(({ kind }) => kind === "video")
  myAudioTrack = myTracks.find(({ kind }) => kind === "audio")

  if (isMobile(navigator).any) {
    document.addEventListener("visibilitychange", visibilityChange)
  }

  room = await Video.connect(accessToken, {
    name: userId,
    tracks: myTracks,
  })

  room.on("participantConnected", function (participant) {})

  room.on("participantDisconnected", function (participant) {
    refreshVideo()
    refreshAudio()
    participant.removeAllListeners()
  })

  function handleTrackChange(event) {
    return function (track) {
      if (track.kind !== "video") {
        refreshAudio()
      } else {
        refreshVideo()
      }
    }
  }

  room.on("trackSubscribed", handleTrackChange("trackSubscribed"))
  room.on("trackUnsubscribed", handleTrackChange("trackUnsubscribed"))
  room.on("trackDisabled", handleTrackChange("trackDisabled"))
  room.on("trackEnabled", handleTrackChange("trackEnabled"))

  refreshVideo()
}

function runClock() {
  clockInterval = setInterval(() => {
    clock = addSeconds(clock, 1)
    formattedClock = format(clock, "mm:ss")
  }, 1000)
}

function stopClock() {
  clearInterval(clockInterval)
  clockInterval = null
}

async function disconnect() {
  if (room) {
    myVideoTrack.stop()
    myAudioTrack.stop()
    room.removeAllListeners()
    room.disconnect()
    room = null

    const searchParams = {
      role: "doctor",
      render: false,
    }
    try {
      await API.patch(`workflows/${workflowId}/views/disconnect`, {
        json: {},
        searchParams,
      })
    } catch (err) {
      console.log(err)
    }
  }
}

function toggleVideo() {
  if (myVideoEnabled) {
    myVideoTrack.disable()
    myVideoEnabled = false
  } else {
    myVideoTrack.enable()
    myVideoEnabled = true
  }
  refreshVideo()
}

function toggleAudio() {
  if (myAudioEnabled) {
    myAudioTrack.disable()
    myAudioEnabled = false
  } else {
    myAudioTrack.enable()
    myAudioEnabled = true
  }
}

async function shareScreen() {
  const stream = await navigator.mediaDevices.getDisplayMedia()
  const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
    name: "screenShare",
  })

  room.localParticipant.publishTrack(screenTrack)

  stream.getVideoTracks()[0].onended = function () {
    room.localParticipant.unpublishTrack(screenTrack)
  }
}

async function endCall() {
  await disconnect()
  joinedCall = false
}

onMount(async function () {
  runClock()
})

onDestroy(async function () {
  stopClock()
  await disconnect()
})
</script>

<svelte:window on:beforeunload="{disconnect}" />
<div class="text-white wrapper">
  {#if !joinedCall}
    <div class="mt-4 type-sub-header">{formattedClock}</div>
    <div class="w-64 my-auto text-center">
      <p class="mb-6 type-sub-header">{patientWaitingText[$workflowLang]}...</p>
      <Button
        type="button"
        on:click="{joinCall}"
        color="primary"
        label="{joinTheVideoCallText[$workflowLang]}"
      />
    </div>
  {:else}
    <div bind:this="{audiosContainerEl}"></div>
    <div class="video-wrapper">
      <span class="mb-4 type-sub-header">{formattedClock}</span>
      <div
        id="patientVideo"
        class="space-y-2 video"
        bind:this="{otherVideosContainerEl}"
      ></div>
      {#if $ready}
        <div class="video" bind:this="{videoContainerEl}"></div>
      {/if}
      <div class="buttons">
        <div class="button" on:click="{toggleAudio}">
          <i class="material-icons">{myAudioEnabled ? 'mic' : 'mic_off'}</i>
        </div>
        <div class="button end-call" on:click="{endCall}">
          <i class="material-icons">call_end</i>
        </div>
        <div class="button" on:click="{toggleVideo}">
          <i class="material-icons">
            {myVideoEnabled ? 'videocam' : 'videocam_off'}
          </i>
        </div>
      </div>
      <div class="w-64">
        <Button
          type="button"
          className="mb-4"
          on:click="{shareScreen}"
          color="primary"
          label="Share screen"
        />
      </div>
    </div>
  {/if}
</div>
